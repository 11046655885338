import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Space, Popconfirm, message } from 'antd';
import { PlusOutlined, EditOutlined, ReloadOutlined,DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import '../App.css';
import ApiGroupsModal from './ApiGroupsModal';
import DocumentationModal from "./DocumentationModal";


function ApiList({ onAddApi, onEditApi }) {
  const [apis, setApis] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [groupOptions, setGroupOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [groupsModalVisible, setGroupsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [editingRowId, setEditingRowId] = useState(null);

  const [documentationModalVisible, setDocumentationModalVisible] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch APIs and filter options when component mounts
  useEffect(() => {
    fetchApis();
    fetchFilterOptions();
  }, [searchText]);


  const fetchApis = async (filters = {}) => {
    try {
      const { group = [], method = [] } = filters;
      const response = await axios.get(`${API_BASE_URL}/config`, {
        params: {
          name: searchText,
          group, // Pass the array of groups
          method, // Pass the array of methods
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      });  // Update the APIs and set total count for pagination
      setApis(response.data);
      setPagination(prev => ({
        ...prev,
        total: response.data.total, // Ensure `total` is set from the response if available
      }));
    } catch (error) {
      message.error('Failed to fetch APIs');
    }
  };

  const fetchFilterOptions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/config/filters`);
      setGroupOptions(response.data.groups);
      setMethodOptions(response.data.methods);
    } catch (error) {
      message.error('Failed to fetch filter options');
    }
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`${API_BASE_URL}/config/${_id}`);
      setApis((prevApis) => prevApis.filter((api) => api._id !== _id));
      message.success('API deleted successfully');
    } catch (error) {
      console.error('Error deleting API:', error);
      message.error('Failed to delete API');
    }
  };

  // const handleTableChange = (newPagination, filters) => {
  //   setPagination(newPagination); // Update pagination state
  //   fetchApis({
  //     group: filters.group && filters.group[0],
  //     method: filters.method && filters.method[0],
  //   });
  // };

  const handleTableChange = (newPagination, filters) => {
    setPagination(newPagination); // Update pagination state
    fetchApis({
      group: filters.group || [],
      method: filters.method || [],
    });
  };

  const handleSortOrderBlur = async (record, e) => {
    const newOrder = Number(e.target.value);
    if (isNaN(newOrder)) {
      message.error("Sort order must be a number");
      return;
    }
    try {
      // Call your new route that only updates the groupSortOrder field.
      await axios.patch(`${API_BASE_URL}/config/sort-order/${record._id}`, { groupSortOrder: newOrder });
      // Update the local state to reflect the change
      setApis((prev) =>
        prev.map((api) =>
          api._id === record._id ? { ...api, groupSortOrder: newOrder } : api
    )
  );
  message.success("Sort order updated successfully");
} catch (error) {
  message.error("Failed to update sort order");
} finally {
  setEditingRowId(null); // exit edit mode after updating
}
};

  const handleRefresh = () => {
    fetchApis(); // Call fetchApis to reload the list
    message.success('API list refreshed');
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1, // Calculate global index
    },
    {
      title: 'API Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filters: groupOptions.map((group) => ({ text: group, value: group })), // Dynamic options
      onFilter: (value, record) => record.group === value,
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      filters: methodOptions.map((method) => ({ text: method, value: method })), // Dynamic options
      onFilter: (value, record) => record.method === value,
    },
    {
      title: "Sort Order",
      dataIndex: "groupSortOrder",
      key: "groupSortOrder",
      sorter: (a, b) => a.groupSortOrder - b.groupSortOrder,
      render: (text, record) => {
        const isEditing = editingRowId === record._id;
        return isEditing ? (
          <Input
            defaultValue={text}
            style={{ width: 80 }}
            onBlur={(e) => handleSortOrderBlur(record, e)}
            onPressEnter={(e) => handleSortOrderBlur(record, e)}
          />
        ) : (
          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onDoubleClick={() => setEditingRowId(record._id)}
          >
            <span>{text}</span>
            <EditOutlined
              onClick={() => setEditingRowId(record._id)}
              style={{ marginLeft: 8 }}
            />
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
           type="link" 
           
            icon={<EditOutlined />}
            onClick={() => onEditApi(record)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this API?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button  type="link"  danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Input
          placeholder="Search by API Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '200px' }}
        />
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddApi}
          >
            Add API
          </Button>
          <Button type="default" onClick={() => setGroupsModalVisible(true)}>
            Manage API Groups
          </Button>
          <Button type="default" onClick={() => setDocumentationModalVisible(true)}>
  Manage Documentation
</Button>

          <Button
            icon={<ReloadOutlined />}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
          </div>
      </div>
      <Table
       className="compact-table"
        columns={columns}
        dataSource={apis}
        rowKey="_id"
        onChange={handleTableChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showTotal: (total) => `Total APIs: ${total}`, // Show total count at bottom right
          onShowSizeChange: (current, size) => {
            setPagination(prev => ({ ...prev, pageSize: size, current: 1 }));
            fetchApis(); // Reload the list with the new page size
          }
        }}
        rowClassName={() => 'compact-row'}  // Add this line
      />
       <ApiGroupsModal visible={groupsModalVisible} onClose={() => setGroupsModalVisible(false)} />
       {documentationModalVisible && (
  <DocumentationModal
    visible={documentationModalVisible}
    onClose={() => setDocumentationModalVisible(false)}
    onDocumentationUpdate={() => {
      // Optionally, trigger a refresh or update state if needed.
    }}
  />
)}

    </div>
  );
}
export default ApiList;
