// src/components/AskAIModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, message, Card, Spin, Divider, Tabs, Select } from 'antd';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { CopyOutlined, CodeOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Option } = Select;

const AskAIModal = ({ visible, onClose }) => {
  // Query/answer states
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [displayAnswer, setDisplayAnswer] = useState(false);

  // Code generation related states
  const [showTabs, setShowTabs] = useState(false); // false means show explanation only
  const [activeTab, setActiveTab] = useState('explanation');
  const [code, setCode] = useState('');
  const [codeLoading, setCodeLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('javascript');
  const [additionalContext, setAdditionalContext] = useState('');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // Dynamic loading messages for answer generation
  const loadingMessages = [
    "Thinking deeply about your question...",
    "Analyzing API documentation...",
    "Generating insights...",
    "Almost there..."
  ];

  useEffect(() => {
    let interval;
    if (loading) {
      let index = 0;
      setLoadingMessage(loadingMessages[index]);
      interval = setInterval(() => {
        index = (index + 1) % loadingMessages.length;
        setLoadingMessage(loadingMessages[index]);
      }, 1500);
    } else {
      setLoadingMessage('');
    }
    return () => clearInterval(interval);
  }, [loading]);

  // Fade-in effect for answer
  useEffect(() => {
    if (answer) {
      setDisplayAnswer(false);
      const timer = setTimeout(() => setDisplayAnswer(true), 300);
      return () => clearTimeout(timer);
    }
  }, [answer]);

  // Handle submission of the query
  const handleSubmit = async () => {
    if (!question.trim()) {
      message.error('Please enter your question.');
      return;
    }
    setLoading(true);
    setAnswer('');
    setCode('');
    setShowTabs(false);
    try {
      const response = await axios.post (`${API_BASE_URL}/ask/`, { userQuestion: question });
      setAnswer(response.data.answer);
      setActiveTab('explanation');
    } catch (error) {
      console.error('Error calling OpenAI endpoint:', error);
      message.error('Failed to get guidance. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Copy functions for answer and code
  const handleCopyAnswer = () => {
    navigator.clipboard.writeText(answer);
    message.success('Answer copied to clipboard');
  };

  const handleCopyCode = (codeText) => {
    navigator.clipboard.writeText(codeText);
    message.success('Code copied to clipboard');
  };

  // Generate code based on the original answer and additional context
  const handleGenerateCode = async () => {
    if (!answer) {
      message.error('No answer available to generate code from.');
      return;
    }
    setCodeLoading(true);
    try {
      const response = await axios.post   (`${API_BASE_URL}/ask/generate-code`, {
       
        language: selectedLanguage,
        additionalContext,
        originalAnswer: answer
      });
      setCode(response.data.code);
    } catch (error) {
      console.error("Error generating code:", error);
      message.error('Failed to generate code. Please try again.');
    } finally {
      setCodeLoading(false);
    }
  };

  // When closing the modal, reset all state
  const handleClose = () => {
    setQuestion('');
    setAnswer('');
    setCode('');
    setShowTabs(false);
    onClose();
  };

  // When user clicks "Code It For Me", reveal the tabbed view and switch to the Code tab
  const handleShowCode = () => {
    setShowTabs(true);
    setActiveTab('code');
  };

  // Header content for the modal
  const headerContent = (
    <div style={{ paddingBottom: 4, marginBottom: 4 }}>
      <h2 style={{ margin: 0, fontSize: '20px', fontWeight: '600' }}>Specter AI Assistant</h2>
      <Divider style={{ margin: '4px 0' }} />
    </div>
  );

  // Button for submitting the query
  const GuidanceButton = (
    <Button
      key="submit"
      type="primary"
      loading={loading}
      onClick={handleSubmit}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        fontWeight: '600'
      }}
    >
      <img
        src="/images/wand.png"
        alt="Magic Wand"
        style={{ width: '20px', height: '20px', objectFit: 'contain' }}
      />
      Submit Query
    </Button>
  );

  // Render explanation content with extra spacing between the copy button and text
  const renderExplanation = () => (
    <div style={{ position: 'relative', paddingTop: '30px' }}>
      <Button
        type="link"
        onClick={handleCopyAnswer}
        style={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CopyOutlined /> Copy Answer
      </Button>
      <div style={{ marginTop: '20px' }}>
        <ReactMarkdown
          children={answer}
          remarkPlugins={[remarkGfm]}
          className="documentation-content"
        />
      </div>
    </div>
  );

  // Custom renderer for code blocks in the Code tab
  const CustomCodeBlock = ({ node, inline, className, children, ...props }) => {
    if (inline) {
      return <code className={className} {...props}>{children}</code>;
    }
    // Get the code text (assuming children is an array with a single string)
    const codeText = children[0];
    return (
      <div style={{ position: 'relative', marginBottom: '24px' }}>
        <div style={{ marginBottom: '8px', textAlign: 'right' }}>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopyCode(codeText)}
          >
            Copy Code
          </Button>
        </div>
        <pre
          className={className}
          {...props}
          style={{
            padding: '20px',
            borderRadius: '8px',
            overflowX: 'auto'
          }}
        >
          <code>{children}</code>
        </pre>
      </div>
    );
  };

  // Render code generation interface inside the Code tab
  const renderCodeTab = () => (
    <div>
      <div style={{ marginBottom: 16 }}>
        <label>Select Language:</label>
        <Select
          value={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value)}
          style={{ width: '100%', marginTop: 4 }}
        >
          <Option value="javascript">JavaScript</Option>
          <Option value="python">Python</Option>
          <Option value="csharp">C#</Option>
          <Option value="java">Java</Option>
          {/* Add other languages as needed */}
        </Select>
      </div>
      <div style={{ marginBottom: 16 }}>
        <label>Additional Context (optional):</label>
        <Input.TextArea
          rows={3}
          value={additionalContext}
          onChange={(e) => setAdditionalContext(e.target.value)}
          placeholder="Provide any extra details about the code you need..."
        />
      </div>
      <Button type="primary" onClick={handleGenerateCode} loading={codeLoading}>
        Generate Code
      </Button>
      {code && (
        <div style={{ marginTop: 16 }}>
          <ReactMarkdown
            children={code}
            remarkPlugins={[remarkGfm]}
            className="documentation-content"
            components={{
              code: CustomCodeBlock
            }}
          />
        </div>
      )}
    </div>
  );

  // Depending on whether the code view is activated, render either a simple explanation or a tabbed view.
  const renderContent = () => {
    if (!showTabs) {
      return (
        <div>
          {renderExplanation()}
          <div style={{ marginTop: 16, textAlign: 'right' }}>
            <Button 
              type="primary" 
              icon={<CodeOutlined />} 
              onClick={handleShowCode}
              style={{
                backgroundColor: '#4a90e2',
                borderColor: '#4a90e2',
                borderRadius: 4,
                padding: '8px 16px',
                fontWeight: '600'
              }}
            >
              Code It For Me
            </Button>
          </div>
        </div>
      );
    }
    return (
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Explanation" key="explanation">
          {renderExplanation()}
        </TabPane>
        <TabPane tab="Code" key="code">
          {renderCodeTab()}
        </TabPane>
      </Tabs>
    );
  };

  return (
    <Modal
      title={headerContent}
      visible={visible}
      onCancel={handleClose}
      footer={
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {GuidanceButton}
          </div>
          <div style={{ textAlign: 'center', marginTop: 24, fontSize: '12px', color: '#777' }}>
            Powered by Specter | AI Assistance Reimagined
          </div>
        </>
      }
      width={800}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto', padding: '24px' }}
    >
      <p style={{ fontSize: '16px', marginBottom: '16px', color: '#555' }}>
        Need help integrating Specter APIs into your gaming projects? Simply type your query below and our assistant will provide tailored guidance.
      </p>
      <Input
        placeholder="Type your query here..."
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        style={{ marginBottom: 16, fontSize: '16px', padding: '8px' }}
      />
      {loading && (
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Spin />
          <div style={{ marginTop: 8, fontSize: '16px', color: '#555' }}>
            {loadingMessage}
          </div>
        </div>
      )}
      {answer && !loading && (
        <div style={{ marginTop: 16 }}>
          {renderContent()}
        </div>
      )}
    </Modal>
  );
};

export default AskAIModal;
