
import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Space,
  Row,
  Col,
  Collapse,
  Typography,
  Divider,
  Checkbox,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';
import AceEditor from "react-ace";
import axios from 'axios';

// Import any Ace “modes” or “themes” you want:
import "ace-builds/src-noconflict/mode-json";
import 'ace-builds/src-noconflict/theme-cobalt';
import 'ace-builds/src-noconflict/theme-github';
// If needed, import more e.g. "ace-builds/src-noconflict/snippets/json"



const { Option } = Select;
const { Panel } = Collapse;
const { Title,Text } = Typography;

function ApiConfigForm({ initialValues = {}, isEditMode = false, onCancel, onSubmit }) {
  const [form] = Form.useForm();
  const [parameterTypeMap, setParameterTypeMap] = useState({});
  const [dataTypeMap, setDataTypeMap] = useState({});
  const [arrayItemTypeMap, setArrayItemTypeMap] = useState({});
  const [hasAttributes, setHasAttributes] = useState(false);
  const [longDescription, setLongDescription] = useState('');
  const [exampleDescriptions, setExampleDescriptions] = useState({});
  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/groups`);
        // Assuming the backend returns groups sorted by sortOrder
        setGroupOptions(response.data);
      } catch (error) {
        message.error("Failed to fetch API groups");
      }
    };
    fetchGroups();
  }, []);


  useEffect(() => {
    if (isEditMode && initialValues) {
      // Set form fields from initialValues
      form.setFieldsValue(initialValues);
      setLongDescription(initialValues.longDescription || '');

          // Initialize exampleDescriptions from initialValues.examples if available
    if (initialValues.examples && Array.isArray(initialValues.examples)) {
      const initialExamples = {};
      initialValues.examples.forEach((ex, idx) => {
        initialExamples[idx] = ex.description || '';
      });
      setExampleDescriptions(initialExamples);
    }
    


      // Initialize state maps based on initialValues
      if (initialValues.parameters) {
        const parameterTypeMapInit = {};
        const dataTypeMapInit = {};
        const arrayItemTypeMapInit = {};
        initialValues.parameters.forEach((param, index) => {
          parameterTypeMapInit[index] = param.parameterType;
          dataTypeMapInit[index] = param.dataType;
          if (param.dataType === 'array') {
            arrayItemTypeMapInit[index] = param.arrayItemType;
          }

          // No need for enumValuesMap as we're handling enumValues directly with Select
        });

        setParameterTypeMap(parameterTypeMapInit);
        setDataTypeMap(dataTypeMapInit);
        setArrayItemTypeMap(arrayItemTypeMapInit);
      }

      setHasAttributes(initialValues.attributes?.length > 0);
    } else {
      form.resetFields();
      setParameterTypeMap({});
      setDataTypeMap({});
      setArrayItemTypeMap({});
      setHasAttributes(false);
      setLongDescription('');
      setExampleDescriptions({});
    }
  }, [isEditMode, initialValues, form]);

  // Handle changes to the parameter type
  const handleParameterTypeChange = (fieldName, value) => {
    setParameterTypeMap((prev) => ({ ...prev, [fieldName]: value }));
    // Reset data type and array item type when parameter type changes
    setDataTypeMap((prev) => ({ ...prev, [fieldName]: undefined }));
    setArrayItemTypeMap((prev) => ({ ...prev, [fieldName]: undefined }));
    // Reset form values for dataType and arrayItemType
    const parameters = form.getFieldValue('parameters');
    if (parameters && parameters[fieldName]) {
      parameters[fieldName].dataType = undefined;
      parameters[fieldName].arrayItemType = undefined;
      form.setFieldsValue({ parameters });
    }
  };

  // Handle changes to the data type
  const handleDataTypeChange = (fieldName, value) => {
    setDataTypeMap((prev) => ({ ...prev, [fieldName]: value }));
    // Reset array item type when data type changes
    if (value !== "array") {
      setArrayItemTypeMap((prev) => ({ ...prev, [fieldName]: undefined }));
      // Reset form value for arrayItemType
      const parameters = form.getFieldValue('parameters');
      if (parameters && parameters[fieldName]) {
        parameters[fieldName].arrayItemType = undefined;
        form.setFieldsValue({ parameters });
      }
    }
  };

  // Handle changes to the array item type
  const handleArrayItemTypeChange = (fieldName, value) => {
    setArrayItemTypeMap((prev) => ({ ...prev, [fieldName]: value }));
  };

  // Add "enum" to getAllowedDataTypes for applicable parameter types
  const getAllowedDataTypes = (parameterType) => {
    switch (parameterType) {
      case "query":
        return ["string", "number", "boolean", "array", "enum"];
      case "path":
        return ["string", "number", "enum"];
      case "header":
        return ["string", "number", "boolean", "enum"];
      case "body":
        return ["string", "number", "boolean", "array", "object", "date", "enum"];
      case "form-data":
        return []; // Form-data has its own handling
      default:
        return [];
    }
  };

  // Handle form submission
  const handleFormSubmit = (values) => {
    const processParameters = (params) => {
      params.forEach((param) => {
        if (param.dataType === "enum") {
          // enumValues are already directly under param
          param.enumValues = param.enumValues || [];
        }

        // Handle objectSchema for 'object' dataType
        if (param.dataType === "object" && param.objectSchema) {
          processObjectSchema(param.objectSchema);
        }

        // Handle arrayItemType and objectSchema for 'array' dataType
        if (param.dataType === "array") {
          if (param.arrayItemType === "enum") {
            param.enumValues = param.enumValues || [];
          }

          if (param.arrayItemType === "object" && param.objectSchema) {
            processObjectSchema(param.objectSchema);
          }
        }
      });
    };

    const processObjectSchema = (objectSchema) => {
      objectSchema.forEach((field) => {
        if (field.fieldType === "enum") {
          // enumValues are already directly under field
          field.enumValues = field.enumValues || [];
        }

        // Recursively handle nested objectSchema
        if (field.fieldType === "object" && field.objectSchema) {
          processObjectSchema(field.objectSchema);
        }

        // Handle arrayItemType and objectSchema for 'array' fieldType
        if (field.fieldType === "array") {
          if (field.arrayItemType === "enum") {
            field.enumValues = field.enumValues || [];
          }

          if (field.arrayItemType === "object" && field.objectSchema) {
            processObjectSchema(field.objectSchema);
          }
        }
      });
    };

    // Start processing parameters
    if (values.parameters) {
      processParameters(values.parameters);
    }
      // Merge exampleDescriptions into each example in the values:
  if (values.examples && Array.isArray(values.examples)) {
    values.examples = values.examples.map((ex, idx) => ({
      ...ex,
      description: exampleDescriptions[idx] || ex.description,
    }));
  }
    values.longDescription = longDescription;
    onSubmit(values); // Call onSubmit with updated values
  };

  // Function to get nested fields (if needed)
  const getNestedFields = (fieldPath) => {
    return form.getFieldsValue(fieldPath);
  };

  return (
    <Row gutter={16} style={{ padding: "20px" }}>
      <Col span={24}>
        <Card title={isEditMode ? 'Edit API Endpoint' : 'Add New API Endpoint'} bordered={false}>

          <Form form={form} layout="vertical" onFinish={handleFormSubmit} initialValues={initialValues}>

            {/* API Basic Information */}
            <Title level={4}>API Basic Information</Title>

            <Form.Item
  name="group"
  label="API Group"
  rules={[{ required: true, message: "Please select the API group" }]}
>
  <Select placeholder="Select API Group">
    {groupOptions.map((group) => (
      <Option key={group.name} value={group.name}>
        {group.name}
      </Option>
    ))}
  </Select>
</Form.Item>

            <Form.Item
              name="name"
              label="API Name"
              rules={[{ required: true, message: "Please enter the API name" }]}
            >
              <Input placeholder="Enter API Name" />
            </Form.Item>
            <Form.Item
  name="groupSortOrder"
  label="Sort Order"
  rules={[{ required: true, message: "Please enter a sort order" }]}
>
  <Input type="number" placeholder="Enter sort order (e.g. 1)" />
</Form.Item>

            <Form.Item name="description" label="API Description">
              <Input.TextArea
                placeholder="Enter API Description"
                autoSize={{ minRows: 2, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item label="Detailed Documentation">

  <Editor
   apiKey="0k7t1yanut3hbo2kq78m4rik6iy2tc50gut6gsh3q2g7wed2"
  value={longDescription}  // Use initialValue to avoid constant re‑rendering
  
  init={{
    height: 300,
    
    menubar: true,
    plugins: [
      'table', 'lists', 'link', 'image', 'code', 'autolink', 'codesample'
    ],
    toolbar:
      'undo redo | formatselect | bold italic underline | ' +
      'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
      'table codesample | code',
    codesample_global_prismjs: true  // Optional: if you want PrismJS styling
  }}
  onEditorChange={(content) => setLongDescription(content)}  // Keep it simple
/>

</Form.Item>


            <Form.Item name="tags" label="Tags">
  <Select mode="tags" placeholder="Add tags (e.g., competition, user-management)" style={{ width: '100%' }} />
</Form.Item>
            <Form.Item
              name="route"
              label="API Route"
              rules={[
                { required: true, message: "Please enter the API route" },
              ]}
            >
              <Input placeholder="Enter API Route" />
            </Form.Item>
            <Form.Item
              name="method"
              label="HTTP Method"
              rules={[
                { required: true, message: "Please select the HTTP method" },
              ]}
            >
              <Select placeholder="Select HTTP Method">
                <Option value="GET">GET</Option>
                <Option value="POST">POST</Option>
                <Option value="PUT">PUT</Option>
                <Option value="DELETE">DELETE</Option>
                <Option value="PATCH">PATCH</Option>
              </Select>
            </Form.Item>

            <Divider />

            {/* Parameters Section */}
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Parameters" key="1">
                <Form.List name="parameters">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => {
                        const { key, name, ...restField } = field;
                        const fieldName = name; // Index of the parameter
                        return (
                          <div
                            key={key}
                            style={{
                              marginBottom: "16px",
                              borderBottom: "1px solid #f0f0f0",
                              paddingBottom: "16px",
                            }}
                          >
                            {/* Remove Button */}
                            <Row>
                              <Col span={24} style={{ textAlign: "right" }}>
                                <Button
                                  type="text"
                                  onClick={() => {
                                    remove(name);
                                    // Remove the state entries associated with this parameter
                                    setParameterTypeMap((prev) => {
                                      const updated = { ...prev };
                                      delete updated[fieldName];
                                      return updated;
                                    });
                                    setDataTypeMap((prev) => {
                                      const updated = { ...prev };
                                      delete updated[fieldName];
                                      return updated;
                                    });
                                    setArrayItemTypeMap((prev) => {
                                      const updated = { ...prev };
                                      delete updated[fieldName];
                                      return updated;
                                    });
                                  }}
                                  icon={<DeleteOutlined />}
                                  danger
                                />
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              {/* First Row: Parameter Name, Description */}
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "name"]}
                                  label="Parameter Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Parameter name is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Parameter Name" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "description"]}
                                  label="Description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Description is required",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Parameter Description" />
                                  </Form.Item>
  </Col>
  <Col span={4}>
    <Form.Item
      name={[name, "isRequired"]}
      valuePropName="checked"
      initialValue={false}
      // Remove extra margin if needed so it aligns well
      style={{ marginBottom: 0 }}
      label="Is Required?"
    >
      <Checkbox />
    </Form.Item>
  </Col>
</Row>

                            <Row gutter={16}>
                              {/* Second Row: Parameter Type, Data Type, Array Item Type */}
                              <Col span={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "parameterType"]}
                                  label="Parameter Type"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Parameter type is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Parameter Type"
                                    onChange={(value) =>
                                      handleParameterTypeChange(
                                        fieldName,
                                        value,
                                      )
                                    }
                                  >
                                    <Option value="query">Query</Option>
                                    <Option value="body">Body</Option>
                                    <Option value="path">Path</Option>
                                    <Option value="header">Header</Option>
                                    <Option value="form-data">Form Data</Option>
                                  </Select>
                                </Form.Item>
                              </Col>

                              {/* Conditionally display Data Type */}
                              {parameterTypeMap[fieldName] &&
                                parameterTypeMap[fieldName] !== "form-data" &&
                                getAllowedDataTypes(parameterTypeMap[fieldName])
                                  .length > 0 && (
                                  <Col span={6}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "dataType"]}
                                      label="Data Type"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Data type is required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Select Data Type"
                                        onChange={(value) =>
                                          handleDataTypeChange(fieldName, value)
                                        }
                                      >
                                        {getAllowedDataTypes(
                                          parameterTypeMap[fieldName],
                                        ).map((dataType) => (
                                          <Option
                                            key={dataType}
                                            value={dataType}
                                          >
                                            {dataType}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                )}

                              {/* Conditionally display Array Item Type */}
                              {dataTypeMap[fieldName] === "array" && (
                                <Col span={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "arrayItemType"]}
                                    label="Array Item Type"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Array item type is required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Array Item Type"
                                      onChange={(value) =>
                                        handleArrayItemTypeChange(
                                          fieldName,
                                          value,
                                        )
                                      }
                                    >
                                      <Option value="string">String</Option>
                                      <Option value="number">Number</Option>
                                      <Option value="boolean">Boolean</Option>
                                      <Option value="object">Object</Option>
                                      <Option value="date">Date</Option>
                                      <Option value="enum">Enum</Option> {/* Added 'enum' option */}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>

                         {/* Nested Object Schema for Array Items */}
{dataTypeMap[fieldName] === "array" &&
  arrayItemTypeMap[fieldName] === "object" && (
    <Form.Item label="Object Schema for Array Items">
      <Form.List name={[name, "objectSchema"]}>
        {(objectFields, { add: addObjectField, remove: removeObjectField }) => (
          <>
            {objectFields.map(({ key: objKey, name: objName, ...objRestField }) => (
              <div key={objKey}>
                <Row gutter={16} style={{ marginBottom: 8 }} align="baseline">
                  <Col span={6}>
                    <Form.Item
                      {...objRestField}
                      name={[objName, "fieldName"]}
                      label="Field Name"
                      rules={[
                        {
                          required: true,
                          message: "Field name is required",
                        },
                      ]}
                    >
                      <Input placeholder="Field Name" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...objRestField}
                      name={[objName, "fieldType"]}
                      label="Field Type"
                      rules={[
                        {
                          required: true,
                          message: "Field type is required",
                        },
                      ]}
                    >
                      <Select placeholder="Field Type">
                        <Option value="string">String</Option>
                        <Option value="number">Number</Option>
                        <Option value="boolean">Boolean</Option>
                        <Option value="array">Array</Option>
                        <Option value="object">Object</Option>
                        <Option value="date">Date</Option>
                        <Option value="enum">Enum</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...objRestField}
                      name={[objName, "description"]}
                      label="Description"
                    >
                      <Input placeholder="Field Description" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
    <Form.Item
      name={[objName, "isRequired"]}
      valuePropName="checked"
      initialValue={false}
      // Remove extra margin if needed so it aligns well
      style={{ marginBottom: 0 }}
      label="Is Required?"
    >
      <Checkbox />
    </Form.Item>
  </Col>

                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button
                        type="text"
                        icon={<MinusCircleOutlined />}
                        onClick={() => removeObjectField(objName)}
                        danger
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Conditionally render Enum Values input fields */}
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    const prevFieldType =
                      prevValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                    const currentFieldType =
                      currentValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                    return prevFieldType !== currentFieldType;
                  }}
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const fieldType = getFieldValue([
                      "parameters",
                      fieldName,
                      "objectSchema",
                      objName,
                      "fieldType",
                    ]);
                    if (fieldType === "enum") {
                      return (
                        <Form.Item
                          {...objRestField}
                          name={[objName, "enumValues"]}
                          label="Enum Values"
                          rules={[
                            { required: true, message: "Please input enum values" },
                          ]}
                        >
                          <Select
                            mode="tags"
                            placeholder="Enter enum values"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      );
                    }
                    return null;
                  }}
                </Form.Item>

                {/* Conditionally render Nested Object Schema if fieldType is 'object' */}
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) => {
                    const prevFieldType =
                      prevValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                    const currentFieldType =
                      currentValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                    return prevFieldType !== currentFieldType;
                  }}
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const fieldType = getFieldValue([
                      "parameters",
                      fieldName,
                      "objectSchema",
                      objName,
                      "fieldType",
                    ]);
                    if (fieldType === "object") {
                      return (
                        <Form.Item label="Nested Object Schema">
                          <Form.List name={[objName, "objectSchema"]}>
                            {(nestedFields, { add: addNestedField, remove: removeNestedField }) => (
                              <>
                                {nestedFields.map(
                                  ({ key: nestedKey, name: nestedName, ...nestedRestField }) => (
                                    <div key={nestedKey}>
                                      <Row gutter={16} style={{ marginBottom: 8 }} align="baseline">
                                        <Col span={6}>
                                          <Form.Item
                                            {...nestedRestField}
                                            name={[nestedName, "fieldName"]}
                                            label="Field Name"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Field name is required",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Field Name" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                          <Form.Item
                                            {...nestedRestField}
                                            name={[nestedName, "fieldType"]}
                                            label="Field Type"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Field type is required",
                                              },
                                            ]}
                                          >
                                            <Select placeholder="Field Type">
                                              <Option value="string">String</Option>
                                              <Option value="number">Number</Option>
                                              <Option value="boolean">Boolean</Option>
                                              <Option value="array">Array</Option>
                                              <Option value="object">Object</Option>
                                              <Option value="date">Date</Option>
                                              <Option value="enum">Enum</Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                          <Form.Item
                                            {...nestedRestField}
                                            name={[nestedName, "description"]}
                                            label="Description"
                                          >
                                            <Input placeholder="Field Description" />
                                          </Form.Item>
                                        </Col>
                                          
  <Col span={4}>
    <Form.Item
    name={[nestedName, "isRequired"]}
    valuePropName="checked"
    initialValue={false}
   
     
      style={{ marginBottom: 0 }}
      label="Is Required?"
    >
      <Checkbox />
    </Form.Item>
  </Col>
                                        <Col span={2}>
                                          <Form.Item label=" ">
                                            <Button
                                              type="text"
                                              icon={<MinusCircleOutlined />}
                                              onClick={() => removeNestedField(nestedName)}
                                              danger
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>

                                      {/* Conditionally render Enum Values input fields for nested fields */}
                                      <Form.Item
                                        shouldUpdate={(prevValues, currentValues) => {
                                          const prevNestedFieldType =
                                            prevValues.parameters?.[fieldName]?.objectSchema?.[
                                              objName
                                            ]?.objectSchema?.[nestedName]?.fieldType;
                                          const currentNestedFieldType =
                                            currentValues.parameters?.[fieldName]?.objectSchema?.[
                                              objName
                                            ]?.objectSchema?.[nestedName]?.fieldType;
                                          return (
                                            prevNestedFieldType !== currentNestedFieldType
                                          );
                                        }}
                                        noStyle
                                      >
                                        {({ getFieldValue }) => {
                                          const nestedFieldType = getFieldValue([
                                            "parameters",
                                            fieldName,
                                            "objectSchema",
                                            objName,
                                            "objectSchema",
                                            nestedName,
                                            "fieldType",
                                          ]);
                                          if (nestedFieldType === "enum") {
                                            return (
                                              <Form.Item
                                                {...nestedRestField}
                                                name={[nestedName, "enumValues"]}
                                                label="Enum Values"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "Please input enum values",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  mode="tags"
                                                  placeholder="Enter enum values"
                                                  style={{ width: "100%" }}
                                                />
                                              </Form.Item>
                                            );
                                          }
                                          return null;
                                        }}
                                      </Form.Item>
                                    </div>
                                  ),
                                )}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => addNestedField()}
                                    icon={<PlusOutlined />}
                                  >
                                    Add Nested Field
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                      );
                    }
                    return null;
                  }}
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => addObjectField()}
                icon={<PlusOutlined />}
              >
                Add Object Field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )}


                            {/* Enum Values for Parameter's dataType "enum" */}
                            {dataTypeMap[fieldName] === "enum" && (
                              <Form.Item
                                name={[name, "enumValues"]}
                                label="Enum Values"
                                rules={[
                                  { required: true, message: "Please input enum values" },
                                ]}
                              >
                                <Select
                                  mode="tags"
                                  placeholder="Enter enum values"
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            )}
                            {/* Enum Values for Array Item Type "enum" */}
{dataTypeMap[fieldName] === "array" && arrayItemTypeMap[fieldName] === "enum" && (
  <Form.Item
    name={[name, "enumValues"]}
    label="Enum Values"
    rules={[
      { required: true, message: "Please input enum values" },
    ]}
  >
    <Select
      mode="tags"
      placeholder="Enter enum values"
      style={{ width: '100%' }}
    />
  </Form.Item>
)}


                           {/* Direct Object Type Schema */}
{dataTypeMap[fieldName] === "object" && (
  <Form.Item label="Object Schema">
    <Form.List name={[name, "objectSchema"]}>
      {(objectFields, { add: addObjectField, remove: removeObjectField }) => (
        <>
          {objectFields.map(({ key: objKey, name: objName, ...objRestField }) => (
            <div key={objKey}>
              <Row gutter={16} style={{ marginBottom: 8 }} align="baseline">
                <Col span={6}>
                  <Form.Item
                    {...objRestField}
                    name={[objName, "fieldName"]}
                    label="Field Name"
                    rules={[
                      {
                        required: true,
                        message: "Field name is required",
                      },
                    ]}
                  >
                    <Input placeholder="Field Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...objRestField}
                    name={[objName, "fieldType"]}
                    label="Field Type"
                    rules={[
                      {
                        required: true,
                        message: "Field type is required",
                      },
                    ]}
                  >
                    <Select placeholder="Field Type">
                      <Option value="string">String</Option>
                      <Option value="number">Number</Option>
                      <Option value="boolean">Boolean</Option>
                      <Option value="array">Array</Option>
                      <Option value="object">Object</Option>
                      <Option value="date">Date</Option>
                      <Option value="enum">Enum</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...objRestField}
                    name={[objName, "description"]}
                    label="Description"
                  >
                    <Input placeholder="Field Description" />
                  </Form.Item>
                </Col>
              
  <Col span={4}>
    <Form.Item
       name={[objName, "isRequired"]}
       valuePropName="checked"
       initialValue={false}
   
     
      style={{ marginBottom: 0 }}
      label="Is Required?"
    >
      <Checkbox />
    </Form.Item>
  </Col>
    <Col span={2}>
                  <Form.Item label=" ">
                    <Button
                      type="text"
                      icon={<MinusCircleOutlined />}
                      onClick={() => removeObjectField(objName)}
                      danger
                    />
                  </Form.Item>
                  </Col>
</Row>


              {/* Conditionally render Enum Values input fields */}
              <Form.Item
                shouldUpdate={(prevValues, currentValues) => {
                  const prevFieldType =
                    prevValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                  const currentFieldType =
                    currentValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                  return prevFieldType !== currentFieldType;
                }}
                noStyle
              >
                {({ getFieldValue }) => {
                  const fieldType = getFieldValue([
                    "parameters",
                    fieldName,
                    "objectSchema",
                    objName,
                    "fieldType",
                  ]);
                  if (fieldType === "enum") {
                    return (
                      <Form.Item
                        {...objRestField}
                        name={[objName, "enumValues"]}
                        label="Enum Values"
                        rules={[
                          { required: true, message: "Please input enum values" },
                        ]}
                      >
                        <Select
                          mode="tags"
                          placeholder="Enter enum values"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    );
                  }
                  return null;
                }}
              </Form.Item>

              {/* Conditionally render Nested Object Schema if fieldType is 'object' */}
              <Form.Item
                shouldUpdate={(prevValues, currentValues) => {
                  const prevFieldType =
                    prevValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                  const currentFieldType =
                    currentValues.parameters?.[fieldName]?.objectSchema?.[objName]?.fieldType;
                  return prevFieldType !== currentFieldType;
                }}
                noStyle
              >
                {({ getFieldValue }) => {
                  const fieldType = getFieldValue([
                    "parameters",
                    fieldName,
                    "objectSchema",
                    objName,
                    "fieldType",
                  ]);
                  if (fieldType === "object") {
                    return (
                      <Form.Item label="Nested Object Schema">
                        <Form.List name={[objName, "objectSchema"]}>
                          {(nestedFields, { add: addNestedField, remove: removeNestedField }) => (
                            <>
                              {nestedFields.map(
                                ({ key: nestedKey, name: nestedName, ...nestedRestField }) => (
                                  <div key={nestedKey}>
                                    <Row gutter={16} style={{ marginBottom: 8 }} align="baseline">
                                      <Col span={6}>
                                        <Form.Item
                                          {...nestedRestField}
                                          name={[nestedName, "fieldName"]}
                                          label="Field Name"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Field name is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Field Name" />
                                        </Form.Item>
                                      </Col>
                                      <Col span={4}>
                                        <Form.Item
                                          {...nestedRestField}
                                          name={[nestedName, "fieldType"]}
                                          label="Field Type"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Field type is required",
                                            },
                                          ]}
                                        >
                                          <Select placeholder="Field Type">
                                            <Option value="string">String</Option>
                                            <Option value="number">Number</Option>
                                            <Option value="boolean">Boolean</Option>
                                            <Option value="array">Array</Option>
                                            <Option value="object">Object</Option>
                                            <Option value="date">Date</Option>
                                            <Option value="enum">Enum</Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={7}>
                                        <Form.Item
                                          {...nestedRestField}
                                          name={[nestedName, "description"]}
                                          label="Description"
                                        >
                                          <Input placeholder="Field Description" />
                                        </Form.Item>
                                      </Col>
                                                                            
  <Col span={4}>
    <Form.Item
    name={[nestedName, "isRequired"]}
    valuePropName="checked"
    initialValue={false}
   
     
      style={{ marginBottom: 0 }}
      label="Is Required?"
    >
      <Checkbox />
    </Form.Item>
  </Col>
                
                                      <Col span={2}>
                                        <Form.Item label=" ">
                                          <Button
                                            type="text"
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => removeNestedField(nestedName)}
                                            danger
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>

                                    {/* Conditionally render Enum Values input fields for nested fields */}
                                    <Form.Item
                                      shouldUpdate={(prevValues, currentValues) => {
                                        const prevNestedFieldType =
                                          prevValues.parameters?.[fieldName]?.objectSchema?.[
                                            objName
                                          ]?.objectSchema?.[nestedName]?.fieldType;
                                        const currentNestedFieldType =
                                          currentValues.parameters?.[fieldName]?.objectSchema?.[
                                            objName
                                          ]?.objectSchema?.[nestedName]?.fieldType;
                                        return prevNestedFieldType !== currentNestedFieldType;
                                      }}
                                      noStyle
                                    >
                                      {({ getFieldValue }) => {
                                        const nestedFieldType = getFieldValue([
                                          "parameters",
                                          fieldName,
                                          "objectSchema",
                                          objName,
                                          "objectSchema",
                                          nestedName,
                                          "fieldType",
                                        ]);
                                        if (nestedFieldType === "enum") {
                                          return (
                                            <Form.Item
                                              {...nestedRestField}
                                              name={[nestedName, "enumValues"]}
                                              label="Enum Values"
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Please input enum values",
                                                },
                                              ]}
                                            >
                                              <Select
                                                mode="tags"
                                                placeholder="Enter enum values"
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                          );
                                        }
                                        return null;
                                      }}
                                    </Form.Item>
                                  </div>
                                ),
                              )}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => addNestedField()}
                                  icon={<PlusOutlined />}
                                >
                                  Add Nested Field
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    );
                  }
                  return null;
                }}
              </Form.Item>
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => addObjectField()}
              icon={<PlusOutlined />}
            >
              Add Object Field
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Form.Item>
)}

                            {/* Form Data Fields */}
                            {parameterTypeMap[fieldName] === "form-data" && (
                              <Form.Item label="Form Data Fields">
                                <Form.List name={[name, "formDataFields"]}>
                                  {(
                                    formDataFields,
                                    {
                                      add: addFormDataField,
                                      remove: removeFormDataField,
                                    },
                                  ) => (
                                    <>
                                      {formDataFields.map(
                                        ({
                                          key: formKey,
                                          name: formName,
                                          ...formRestField
                                        }) => (
                                          <Row
                                            key={formKey}
                                            gutter={16}
                                            style={{ marginBottom: 8 }}
                                            align="baseline"
                                          >
                                            <Col span={6}>
                                              <Form.Item
                                                {...formRestField}
                                                name={[formName, "fieldName"]}
                                                label="Field Name"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Field name is required",
                                                  },
                                                ]}
                                              >
                                                <Input placeholder="Field Name" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                              <Form.Item
                                                {...formRestField}
                                                name={[formName, "fieldType"]}
                                                label="Field Type"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Field type is required",
                                                  },
                                                ]}
                                              >
                                                <Select placeholder="Field Type">
                                                  <Option value="string">
                                                    String
                                                  </Option>
                                                  <Option value="file">
                                                    File
                                                  </Option>
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                              <Form.Item
                                                {...formRestField}
                                                name={[formName, "description"]}
                                                label="Description"
                                              >
                                                <Input placeholder="Field Description" />
                                              </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                              <Form.Item label=" ">
                                                <Button
                                                  type="text"
                                                  icon={<MinusCircleOutlined />}
                                                  onClick={() =>
                                                    removeFormDataField(
                                                      formName,
                                                    )
                                                  }
                                                  danger
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        ),
                                      )}
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          onClick={() => addFormDataField()}
                                          icon={<PlusOutlined />}
                                        >
                                          Add Form Data Field
                                        </Button>
                                      </Form.Item>
                                    </>
                                  )}
                                </Form.List>
                              </Form.Item>
                            )}
                          </div>
                        );
                      })}
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        block
                        style={{ marginTop: "16px" }}
                      >
                        Add Parameter
                      </Button>
                    </>
                  )}
                </Form.List>
              </Panel>
            </Collapse>
            <Divider />

            {/* Checkbox to toggle the Attributes Section */}
            <Form.Item>
              <Checkbox
                checked={hasAttributes}
                onChange={(e) => setHasAttributes(e.target.checked)}
              >
                Does this API have attributes?
              </Checkbox>
            </Form.Item>

            {/* Attributes Section */}
            {hasAttributes && (
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Attributes" key="1">
                  <Form.List name="attributes">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Attribute name is required",
                                },
                              ]}
                            >
                              <Input placeholder="Attribute Name" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "description"]}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder="Attribute Description" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "responseType"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Response type is required",
                                },
                              ]}
                            >
                              <Select placeholder="Response Type">
                                <Option value="string">String</Option>
                                <Option value="number">Number</Option>
                                <Option value="boolean">Boolean</Option>
                                <Option value="array">Array</Option>
                                <Option value="object">Object</Option>
                                <Option value="date">Date</Option>
                                <Option value="enum">Enum</Option> {/* Added 'enum' option */}
                              </Select>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            block
                          >
                            Add Attribute
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Panel>
              </Collapse>
            )}
            <Collapse>
  <Panel header="Required Headers" key="requiredHeaders" forceRender={true}>
    <Form.List name="requiredHeaders">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{ display: 'flex', marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'name']}
                label="Header Name"
                rules={[{ required: true, message: 'Header name is required' }]}
              >
                <Input placeholder="e.g. Authorization" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'description']}
                label="Description"
              >
                <Input placeholder="Describe the header usage" />
              </Form.Item>

              <Button
                type="text"
                danger
                onClick={() => remove(name)}
              >
                Remove
              </Button>
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              Add Required Header
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Panel>
</Collapse>


<Collapse>
  <Panel header="Examples" key="examplesPanel"forceRender={true}>
    <Form.List name="examples">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div
              key={key}
              style={{
                marginBottom: "16px",
                borderBottom: "1px solid #f0f0f0",
                paddingBottom: "16px",
              }}
            >
              {/* Remove button at top-right */}
              <Row justify="end">
                <Button type="text" danger onClick={() => remove(name)}      icon={<MinusCircleOutlined />}>
                
                </Button>
              </Row>

              {/* SCENARIO & DESCRIPTION */}
              <Form.Item
                {...restField}
                name={[name, 'scenario']}
                label="Scenario"
                rules={[{ required: true, message: 'Scenario is required' }]}
              >
                <Input placeholder="Short scenario name (e.g. 'Create User')" />
              </Form.Item>

            
      
<Form.Item
  {...restField}
  name={[name, 'description']}
  label="Scenario Description"
  rules={[{ required: true, message: 'Description is required' }]}
>
  <Editor
      apiKey="0k7t1yanut3hbo2kq78m4rik6iy2tc50gut6gsh3q2g7wed2"
    init={{
      height: 300,
     
      menubar: true,
      plugins: [
        'table', 'lists', 'link', 'image', 'code', 'autolink', 'codesample'
      ],
      toolbar:
        'undo redo | formatselect | bold italic underline | ' +
        'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
        'table codesample | code',
      codesample_global_prismjs: true  // Optional: if you want PrismJS styling
    }}
    // Instead of reading from form.getFieldValue, use the dedicated state:
    value={exampleDescriptions[name] || ''}
    // On change, simply update the exampleDescriptions state:
    onEditorChange={(content) => {
      setExampleDescriptions((prev) => ({ ...prev, [name]: content }));
    }}
  />
</Form.Item>
              {/* REQUEST BLOCK */}
              <Typography.Title level={5}>Request</Typography.Title>

              <Form.Item
                {...restField}
                name={[name, 'request', 'method']}
                label="HTTP Method"
                rules={[{ required: true, message: 'Method is required' }]}
              >
                <Select>
                  <Option value="GET">GET</Option>
                  <Option value="POST">POST</Option>
                  <Option value="PUT">PUT</Option>
                  <Option value="DELETE">DELETE</Option>
                  <Option value="PATCH">PATCH</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'request', 'url']}
                label="URL"
                rules={[{ required: true, message: 'URL is required' }]}
              >
                <Input placeholder="e.g. /api/v1/users" />
              </Form.Item>

              {/* REQUEST.HEADERS (JSON) */}
              <Form.Item label="Request Headers">
                <Form.Item
                  {...restField}
                  // no actual label here, so we nest it
                  name={[name, 'request', 'headers']}
                  // rules (optional) if you want to ensure valid JSON
                  // you could do custom validation
                  noStyle
                >
                  {/* ACE Editor for JSON */}
                  <AceEditor
                    mode="json"
                    theme="cobalt"
                    name={`requestHeaders_${key}`}
                    style={{  height: '200px',width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)', }}
                    onChange={(value) => {
                      // Update the form field whenever the editor changes
                      const currentExamples = form.getFieldValue('examples') || [];
                      currentExamples[name] = currentExamples[name] || {};
                      currentExamples[name].request = currentExamples[name].request || {};
                      currentExamples[name].request.headers = value;
                      form.setFieldsValue({ examples: currentExamples });
                    }}
                    value={
                      form.getFieldValue(['examples', name, 'request', 'headers']) || ''
                    }
                    setOptions={{ useWorker: false }}
                  />
                </Form.Item>
              </Form.Item>

              {/* REQUEST.BODY (JSON) */}
              <Form.Item label="Request Body">
                <Form.Item
                  {...restField}
                  name={[name, 'request', 'body']}
                  noStyle
                >
                  <AceEditor
                    mode="json"
                    theme="cobalt"
                    name={`requestBody_${key}`}
                    style={{  height: '600px',width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)', }}
                    onChange={(value) => {
                      const currentExamples = form.getFieldValue('examples') || [];
                      currentExamples[name] = currentExamples[name] || {};
                      currentExamples[name].request = currentExamples[name].request || {};
                      currentExamples[name].request.body = value;
                      form.setFieldsValue({ examples: currentExamples });
                    }}
                    value={
                      form.getFieldValue(['examples', name, 'request', 'body']) || ''
                    }
                    setOptions={{ useWorker: false }}
                  />
                </Form.Item>
              </Form.Item>

              {/* RESPONSE BLOCK */}
              <Typography.Title level={5}>Response</Typography.Title>
              <Form.Item
                {...restField}
                name={[name, 'response', 'status']}
                label="HTTP Status"
                rules={[{ required: true, message: 'Status is required' }]}
              >
                <Input placeholder="e.g. 200" />
              </Form.Item>

              {/* RESPONSE.HEADERS (JSON) */}
              <Form.Item label="Response Headers">
                <Form.Item
                  {...restField}
                  name={[name, 'response', 'headers']}
                  noStyle
                >
                  <AceEditor
                    mode="json"
                    theme="cobalt"
                    name={`responseHeaders_${key}`}
                    style={{  height: '200px',width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)', }}
                    
                    onChange={(value) => {
                      const currentExamples = form.getFieldValue('examples') || [];
                      currentExamples[name] = currentExamples[name] || {};
                      currentExamples[name].response = currentExamples[name].response || {};
                      currentExamples[name].response.headers = value;
                      form.setFieldsValue({ examples: currentExamples });
                    }}
                    value={
                      form.getFieldValue(['examples', name, 'response', 'headers']) || ''
                    }
                    setOptions={{ useWorker: false }}
                  />
                </Form.Item>
              </Form.Item>

              {/* RESPONSE.BODY (JSON) */}
              <Form.Item label="Response Body" required>
                <Form.Item
                  {...restField}
                  name={[name, 'response', 'body']}
                  rules={[{ required: true, message: 'Response body is required' }]}
                  noStyle
                >
                  <AceEditor
                    mode="json"
                    theme="cobalt"
                    name={`responseBody_${key}`}
                    style={{  height: '600px',width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)', }}
                    onChange={(value) => {
                      const currentExamples = form.getFieldValue('examples') || [];
                      currentExamples[name] = currentExamples[name] || {};
                      currentExamples[name].response = currentExamples[name].response || {};
                      currentExamples[name].response.body = value;
                      form.setFieldsValue({ examples: currentExamples });
                    }}
                    value={
                      form.getFieldValue(['examples', name, 'response', 'body']) || ''
                    }
                    setOptions={{ useWorker: false }}
                  />
                </Form.Item>
              </Form.Item>
            </div>
          ))}
          {/* ADD EXAMPLE BUTTON */}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              Add Example
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </Panel>
</Collapse>



            <Form.Item style={{ paddingTop: '16px' }}>
              <Button type="primary" htmlType="submit" >
                {isEditMode ? 'Update API Configuration' : 'Save API Configuration'}
              </Button>
              <Button style={{ marginLeft: '8px' }} onClick={onCancel}>
                Cancel
              </Button>
            </Form.Item>

          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default ApiConfigForm;
