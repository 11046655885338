// src/components/ApiKeySelectorModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, Row, Col, message, Spin } from 'antd';
import ProtectedImage from './ProtectedImage';
const { Option } = Select;

// Mapping from modal environment names to the header value


const ApiKeySelectorModal = ({ 
  visible, 
  onSelect, 
  onCancel, 
  orgId, 
  authToken, 
  orgApiKeys,
  globalEnv ,
  devIp 
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [SelectedModalEnv, setSelectedModalEnv] = useState(null);
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);

  const environments = ['Development', 'Quality Assurance', 'Production'];
  
  // Default background colors for apps without an icon
  const defaultColors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff5733', '#33c1ff', '#85ff33'];

  // Compute the organization key for the image (same as for get apps)
  const getOrgKey = () => {
    if (SelectedModalEnv === 'Development') {
      return orgApiKeys?.devAPIKey || '';
    } else if (SelectedModalEnv === 'Quality Assurance') {
      return orgApiKeys?.stagingAPIKey || '';
    } else if (SelectedModalEnv === 'Production') {
      return orgApiKeys?.productionAPIKey || '';
    }
    return '';
  };

  const handleEnvNext = () => {
    if (!SelectedModalEnv) {
      message.error('Please select an environment');
      return;
    }
    setCurrentStep(2);
    fetchApps();
  };

  const fetchApps = async () => {
    setLoading(true);
    try {
     
      const envHeader = globalEnv
      // Use the organization’s API key for the selected environment.
      const orgKey = getOrgKey();
      // Prepare the payload.
      const payload = { organisationId: orgId };
      const res = await fetch('https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/app/get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
          'env': envHeader,
          'Api-Key': orgKey,
          ...(envHeader === 'development' && devIp && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
    
        },
        body: JSON.stringify(payload),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.message || 'Failed to fetch apps');
      }
      // Expect the API to return an array of apps in data.data.projectDetails.
      const appsData = data.data.projectDetails || [];
      setApps(appsData);
    } catch (error) {
      message.error(`Error fetching apps: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAppSelect = (app) => {
    // From the selected app, choose the correct client API key.
    let selectedApiKey = '';
    if (SelectedModalEnv === 'Development') {
      selectedApiKey = app.apiKeys?.clientDevAPIKey?.[0] || '';
    } else if (SelectedModalEnv === 'Quality Assurance') {
      selectedApiKey = app.apiKeys?.clientStagingAPIKey?.[0] || '';
    } else if (SelectedModalEnv === 'Production') {
      selectedApiKey = app.apiKeys?.clientProductionAPIKey?.[0] || '';
    }
    if (!selectedApiKey) {
      message.error('No API key available for this app in the selected environment');
      return;
    }
    onSelect(selectedApiKey);
    // Reset modal state for next use.
    setCurrentStep(1);
    setSelectedModalEnv(null);
    setApps([]);
  };

  const handleBack = () => {
    setCurrentStep(1);
    setApps([]);
  };

  useEffect(() => {
    if (!visible) {
      // Reset when modal closes.
      setCurrentStep(1);
      setSelectedModalEnv(null);
      setApps([]);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onCancel();
        setCurrentStep(1);
        setSelectedModalEnv(null);
        setApps([]);
      }}
      footer={null}
      title="Select Your API Key"
    >
      {currentStep === 1 && (
        <div>
          <h3>Select Your Environment</h3>
          <Select
            style={{ width: '100%', marginBottom: 20 }}
            placeholder="Choose an environment"
            onChange={value => setSelectedModalEnv(value)}
            value={SelectedModalEnv}
          >
            {environments.map(env => (
              <Option key={env} value={env}>
                {env}
              </Option>
            ))}
          </Select>
          <Button type="primary" onClick={handleEnvNext} block>
            Next
          </Button>
        </div>
      )}
      {currentStep === 2 && (
        <div>
          <h3>Select Your App</h3>
          {loading ? (
            <Spin tip="Loading apps..." />
          ) : (
            <Row gutter={[32, 32]}>
              {apps.length > 0 ? (
                apps.map((app, index) => (
                    <Col key={app.id} xs={24} sm={12} md={6} lg={6} style={{ textAlign: 'center' }}>
                    <div 
                      onClick={() => handleAppSelect(app)} 
                      style={{ 
                        cursor: 'pointer', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center' 
                      }}
                    >
                      {app.iconUrl ? (
                        <ProtectedImage
                          src={app.iconUrl}
                          apiKey={getOrgKey()}
                          alt={app.name}
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: 'cover',
                            borderRadius: '20%',
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 80,
                            height: 80,
                            borderRadius: '20%',
                            backgroundColor: defaultColors[index % defaultColors.length],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 18,
                            color: 'white',
                          }}
                        >
                    {app.name.charAt(0).toUpperCase()}

                        </div>
                      )}
                      <div style={{ marginTop: 4, fontSize: '12px' }}>
                        {app.name}
                      </div>
                    </div>
                  </Col>
                  
                ))
              ) : (
                <Col span={24}>
                  <p>No apps available for the selected environment.</p>
                </Col>
              )}
            </Row>
          )}
          <Button style={{ marginTop: 20 }} onClick={handleBack}>
            Back
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ApiKeySelectorModal;
