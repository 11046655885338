import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { SwapOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';

const UserDropdown = ({ memberName, currentOrg, onSwitchOrg, onLogout }) => {
  const menu = (
    <Menu>
      {/* Non-clickable header showing current organization */}
      <Menu.Item key="current-org" disabled style={{ cursor: 'default', fontWeight: 'bold' }}>
        {currentOrg ? ` ${currentOrg.name}` : 'No Organization Selected'}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="switch-org" onClick={onSwitchOrg}>
        <SwapOutlined style={{ marginRight: 8 }} />
        Switch Organization
      </Menu.Item>
      <Menu.Item key="logout" onClick={onLogout}>
        <LogoutOutlined style={{ marginRight: 8 }} />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="text" style={{ color: '#ffffff' }}>
        Hi, {memberName} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default UserDropdown;
