// src/App.js
import {Popover, Avatar} from 'antd';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { Layout, Typography,Switch,Alert, Divider,Menu,Tag, List,Select, Card,Drawer,Modal, Button, Input, message, Form, Row, Col, Dropdown, Tooltip, Space,Collapse } from 'antd';
import ApiForm from './components/ApiForm';
import Joyride, { STATUS } from 'react-joyride';
import RecipeList from './components/RecipeList';
import RecipeForm from './components/RecipeForm';
import RecipeRunner from './components/RecipeRunner';
import ExampleDrawerContent from './components/ExampleDrawerContent';
import ApiList from './components/ApiList';
import HistoryModal from './components/HistoryModal';
import ApiResponse from './components/ApiResponse';
import RequestCodeDisplay from './components/RequestCodeDisplay';
import OrgSwitcher from './components/OrgSwitcher';
import { apiService } from './services/apiService';
import ApiKeySelectorModal from './components/ApiKeySelectorModal';
import AskAIModal from './components/AskAIModal';

import {CheckOutlined } from '@ant-design/icons';

import UserDropdown from './components/UserDropdown';
import {
  ExpandOutlined,
  CompressOutlined,
  CopyOutlined,
  ArrowRightOutlined,
  MenuOutlined,
  ToolOutlined,
  InfoCircleOutlined,
  ReadOutlined,
  AppstoreOutlined,       // NEW: grid icon for our menu button
  HistoryOutlined,        // NEW: for History action
  LoginOutlined,          // NEW: for Log in
  UserOutlined,           // NEW: for logged-in user display
  SwapOutlined,
DownOutlined,
  DeleteOutlined,
 
} from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';

// Import Google OAuth
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

// Import UUID if needed
// import { v4 as uuidv4 } from 'uuid';
import ApiConfigForm from './components/ApiConfigForm'; // New component for configuration
import axios from 'axios';
import { ConfigProvider } from 'antd';
import { EyeOutlined,RocketOutlined, EyeInvisibleOutlined, ApiOutlined, QuestionCircleOutlined } from '@ant-design/icons'; // Import the icons
import {
  FacebookOutlined,

  InstagramOutlined,
  LinkedinOutlined,
  BookOutlined
} from '@ant-design/icons';
import './App.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import hljs from 'highlight.js';
// Choose any official highlight.js theme:
import 'highlight.js/styles/atom-one-dark.css';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Option } = Select;
const { Text, Link } = Typography;


function RouteCopyButton({ route }) {
  const [copied, setCopied] = useState(false);

  const handleCopyRoute = () => {
    navigator.clipboard.writeText(route).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };

  return (
    <Button
      type="text"
      icon={copied ? <CheckOutlined /> : <CopyOutlined />}
      onClick={handleCopyRoute}
      style={{ padding: '0 4px' }}
    >
      {copied ? 'Copied!' : 'Copy'}
    </Button>
  );
}



function App() {
  const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey') || '');
  const [bearerToken, setBearerToken] = useState(localStorage.getItem('bearerToken') || ''); // Stored as playerToken
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [response, setResponse] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('Node'); // Default language
  const [selectedEnv, setSelectedEnv] = useState('console'); // Default to 'staging'
  const [codeSnippet, setCodeSnippet] = useState(''); // Code snippet for the request
  const [requestParams, setRequestParams] = useState({}); // Store the request parameters
  const [editorExpanded, setEditorExpanded] = useState(false); // Editor expanded state
  const [isDevMode, setIsDevMode] = useState(false); // Toggle for Dev Mode
  const [showCredentials, setShowCredentials] = useState(true); // State to control the visibility
  const [apiGroups, setApiGroups] = useState([]); // State to store dynamic API groups
  const [devIp, setDevIp] = useState(localStorage.getItem('devIp') || ''); // State to store development server URL
  const [showDevServerCredentials, setShowDevServerCredentials] = useState(true); // State to control the visibility
  const prevIsDevModeRef = useRef(isDevMode);
  const [isAddingApi, setIsAddingApi] = useState(false);
  const [isEditingApi, setIsEditingApi] = useState(false);
  const [editingApiData, setEditingApiData] = useState(null);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [showApiKeySelector, setShowApiKeySelector] = useState(false);
    const [docsDrawerVisible, setDocsDrawerVisible] = useState(false);
    const [examplesDrawerVisible, setExamplesDrawerVisible] = useState(false);
  const [isRecipeMode, setIsRecipeMode] = useState(false);
  const [announcementVisible, setAnnouncementVisible] = useState(true);
  // States for controlling recipe form
  const [isAddingRecipe, setIsAddingRecipe] = useState(false);
  const [isEditingRecipe, setIsEditingRecipe] = useState(false);
  const [editingRecipeData, setEditingRecipeData] = useState(null);

  const [isRunningRecipe, setIsRunningRecipe] = useState(false);
  const [runningRecipeData, setRunningRecipeData] = useState(null);
 // ... your other state variables
 const [helpVisible, setHelpVisible] = useState(false);
 const [documentationTopics, setDocumentationTopics] = useState([]);
 const [docSearchQuery, setDocSearchQuery] = useState("");
 const [filteredDocs, setFilteredDocs] = useState([]);
 const [selectedDoc, setSelectedDoc] = useState(null);
 const [helpContent, setHelpContent] = useState({ title: '', content: '' });
 const [helpDrawerFullscreen, setHelpDrawerFullscreen] = useState(false);

 const [askAIModalVisible, setAskAIModalVisible] = useState(false);
// NEW: State for controlling the grid menu Drawer
const [gridMenuVisible, setGridMenuVisible] = useState(false);


const [groupOptions, setGroupOptions] = useState([]);


const [examplesDrawerFullscreen, setExamplesDrawerFullscreen] = useState(false);

// For API Reference drawe
const [apiRefDrawerFullscreen, setApiRefDrawerFullscreen] = useState(false);
const [askaiButtonBottom, setAskaiButtonBottom] = useState(24);
const footerRef = useRef(null);

const [loginStep, setLoginStep] = useState(0);

const [orgs, setOrgs] = useState([]);
const [selectedOrg, setSelectedOrg] = useState(null);


const [orgApiKeys, setOrgApiKeys] = useState({});

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  const [isRequestExpanded, setIsRequestExpanded] = useState(false);
const SPECTER_ENV_URL = process.env.REACT_APP_SPECTER_ENV_URL;
const [historyModalVisible, setHistoryModalVisible] = useState(false);

useEffect(() => {
  const handleStorageChange = () => {
    setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
    setMemberName(localStorage.getItem('memberName') || '');
    setAuthToken(localStorage.getItem('authToken') || '');
  };

  window.addEventListener('storage', handleStorageChange);

  return () => {
    window.removeEventListener('storage', handleStorageChange);
  };
}, []);

// A palette of soft, muted colors
const tagColors = [
  '#5DADE2', // Soft Blue
  '#48C9B0', // Soft Teal
  '#F5B041', // Soft Orange
  '#EC7063', // Soft Red
  '#AF7AC5', // Soft Purple
];


  // The active client API key (from the selected project/app)




// Function that returns a color based on the index
const getTagColor = (index) => {
  return tagColors[index % tagColors.length];
};


const handleLogout = () => {
  // Clear authentication details from localStorage
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('memberName');
  localStorage.removeItem('authToken');

  // Clear API credentials if necessary
  localStorage.removeItem('apiKey');
  localStorage.removeItem('bearerToken');

  // Clear organization selection
  localStorage.removeItem('selectedOrgId');

  // Reset state variables
  setIsLoggedIn(false);
  setMemberName('');
  setAuthToken('');
  setApiKey('');
  setBearerToken('');
  setSelectedOrg(null);
  setLoginStep(0); // Reset the login step so that the user sees the proper login screen

  message.success('You have been logged out.');
};

  // **Authentication State Variables**
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [memberName, setMemberName] = useState(localStorage.getItem('memberName') || '');
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || '');
  
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


// ... inside your App component, before the return statement, define your grid menu items:
const gridMenuItems = [
  {
    key: 'help',
    label: 'Guide',
    color: '#1890ff',
   
    icon: <ReadOutlined style={{ fontSize: 24 }} />,
    onClick: () => {
      setHelpVisible(true); // Simply open the drawer; fetching is done via useEffect
    },
  },
// In your gridMenuItems array, add or modify an item to open the docs
{
  key: 'apiDocs',
  label: 'API Docs',
  color: '#722ed1',
  icon: <ApiOutlined style={{ fontSize: 24 }} />,
  onClick: () => {
    // Suppose we want to auto-open the doc for “Get App Info”
    const defaultApiName = 'Get App Info';

    // Find that API in the grouped config
    let foundApi = null;
    for (const group of apiGroups) {
      const match = group.apis.find(api => api.name === defaultApiName);
      if (match) {
        foundApi = match;
        break;
      }
    }

    if (foundApi) {
      // Update selectedApi so the docs drawer shows that API
      setSelectedApi(foundApi);
      // Show the drawer
      setDocsDrawerVisible(true);
    } else {
      message.error(`Could not find "${defaultApiName}" in the current API list.`);
    }
  },
},


  {
    key: 'history',
    label: 'History',
    color: '#52c41a',
    icon: <HistoryOutlined style={{ fontSize: 24 }} />,
    onClick: () => {
      if (!isLoggedIn) {
        message.info('You need to log in to view and replay your request history.');
      } else {
        setHistoryModalVisible(true);
      }
    },
  },
  {
    key: 'launchSpecter',
    label: 'Specter',
    color: '#faad14',
    icon: (
      <div
        style={{
          width: '24px',
          height: '24px',
          overflow: 'hidden',       // Make sure the image can’t overflow
          display: 'inline-block',  // Helps keep the container’s size
        }}
      >
        <img
          src="/images/specter_new_1.svg"
          alt="Specter Logo"
          style={{
            width: '24px',
            height: '24px',
            objectFit: 'contain', // or 'cover'
            display: 'block',     // Removes inline gap
          }}
        />
      </div>
    ),
    onClick: () => {
      window.open('https://console.specterapp.xyz', '_blank');
    },
  },
  
  ...(!isProductionMode
    ? [
        {
          key: 'manageAPIs',
          label: 'Manage',

          color: '#f5222d',
          icon: <ToolOutlined style={{ fontSize: 24 }} />,
          onClick: () => {
            // forcibly set dev mode ON
            setIsDevMode(true);
            // forcibly set recipe mode OFF
            setIsRecipeMode(false);
            // Also reset anything else if needed (similar to toggleDevMode logic)
            setSelectedGroup(null);
            setSelectedApi(null);
            setIsAddingApi(false);
            setIsEditingApi(false);
            setEditingApiData(null);
            setCodeSnippet('');
            setResponse(null);
          },
        },
        {
          key: 'recipes',
           label: 'Recipes',

          color: '#722ed1',
          icon: <BookOutlined style={{ fontSize: 24 }} />,
          onClick: () => {
            // forcibly set recipe mode ON
            setIsRecipeMode(true);
            // forcibly set dev mode OFF
            setIsDevMode(false);
            // Also reset any dev states if needed
            setSelectedGroup(null);
            setSelectedApi(null);
            setIsAddingApi(false);
            setIsEditingApi(false);
            setEditingApiData(null);
            setCodeSnippet('');
            setResponse(null);
          },
        },
      ]
    : []),
   
    

];



useEffect(() => {
  const handleScroll = () => {
    if (footerRef.current) {
      const footerRect = footerRef.current.getBoundingClientRect();
      // Check if footer is visible in the viewport
      if (footerRect.top < window.innerHeight) {
        // Calculate how much the footer overlaps the bottom of the viewport
        const overlap = window.innerHeight - footerRect.top;
        // Increase the bottom offset to keep the button above the footer
        setAskaiButtonBottom(24 + overlap);
      } else {
        setAskaiButtonBottom(24);
      }
    }
  };

  window.addEventListener('scroll', handleScroll);
  // Run once on mount to set initial position
  handleScroll();

  return () => window.removeEventListener('scroll', handleScroll);
}, []);




// 1) Use highlight.js as usual, then do a post-processing step:
useEffect(() => {
  if (helpVisible && helpContent.content) {
    // 2) Run highlight
    hljs.highlightAll();

    // 3) Insert copy buttons
    const container = document.querySelector('.documentation-content');

    if (container) {
      const codeBlocks = container.querySelectorAll('pre > code');
      codeBlocks.forEach((codeBlock) => {
        // If we haven’t already added a button, do so
        if (!codeBlock.parentElement.querySelector('.copy-button')) {
          // Create the button
          const button = document.createElement('button');
          button.className = 'copy-button';

          // Default (copy) icon and text
          const copyIcon = `
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <!-- A “copy” icon, simplified path here -->
              <path d="M464 0H144C117.49 0 96 21.49 96 48v80h48V48h320v320h-80v48h80c26.51 
              0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM368 128H48c-26.51 
              0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h320c26.51 
              0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zm0 
              368H48V176h320v320z"></path>
            </svg>
          `;
          const checkIcon = `
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <!-- A “check” icon, simplified path here -->
              <path d="M173.898 439.404l-166.4-166.4 35.818-35.818 
              130.582 130.582 300.782-300.782 35.818 
              35.818-336.6 336.6z"></path>
            </svg>
          `;

          // The default HTML for the button
          const copyButtonHTML = `
            <span class="copy-btn-icon">${copyIcon}</span>
            <span class="copy-btn-text">Copy</span>
          `;
          button.innerHTML = copyButtonHTML;

          // Insert the button in the <pre> so it’s visually near code
          codeBlock.parentElement.style.position = 'relative';
          button.style.position = 'absolute';
          button.style.top = '8px';
          button.style.right = '8px';

          // 4) Bind copy
          button.onclick = () => {
            const codeToCopy = codeBlock.innerText;

            navigator.clipboard.writeText(codeToCopy).then(() => {
              console.log('Code copied to clipboard');

              // Swap to "Copied!" checkmark
              const oldHTML = button.innerHTML;
              button.innerHTML = `
                <span class="copy-btn-icon">${checkIcon}</span>
                <span class="copy-btn-text">Copied!</span>
              `;

              // Revert after 1.5 seconds
              setTimeout(() => {
                button.innerHTML = oldHTML;
              }, 1500);
            });
          };

          codeBlock.parentElement.appendChild(button);
        }
      });
    }
  }
}, [helpVisible, helpContent.content]);


  const menu = (
    <Menu>
      
      <Menu.Item key="1">
        <a href="https://manual.specterapp.xyz/specter-user-manual" target="_blank" rel="noopener noreferrer">
          Specter Manual
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="https://doc.specterapp.xyz" target="_blank" rel="noopener noreferrer">
          API Documentation
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a href="https://medium.com/specter-chronicles" target="_blank" rel="noopener noreferrer">
          Blogs
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <a href="mailto:hello@specterapp.xyz" target="_blank" rel="noopener noreferrer">
          Contact Us
        </a>
      </Menu.Item>
      <Menu.Item key="5">
        <a href="https://discord.com/invite/BZSF94yg2K" target="_blank" rel="noopener noreferrer">
          Join Discord Community
        </a>
      </Menu.Item>
      <Menu.Item key="6">
        <a href="https://specter.canny.io/feedback" target="_blank" rel="noopener noreferrer">
          Submit Feedback
        </a>
      </Menu.Item>
  
      
      
      </Menu>
    );

  // State for Joyride
  const [tourActive, setTourActive] = useState(false);
  const [tourSteps, setTourSteps] = useState([
    {
      target: '.api-key-input',
      content: 'This is where you’ll input your unique API key. Enter it here to authorize and securely access the API. Keep it safe!',
      placement: 'top',
    },
    {
      target: '.api-group-select',
      content: 'Choose an API group to explore the available endpoints.',
      placement: 'top',
    },
    {
      target: '.api-select',
      content: 'Now, select an API endpoint within the chosen group. Each endpoint offers a specific action or resource you can use in your application.',
      placement: 'top',
    },
    {
      target: '.code-snippet-section',
      content: 'Here, you’ll see sample code tailored to your selected API request, ready for you to copy and integrate into your app.',
      placement: 'top',
    },
    {
      target: '.response-section',
      content: 'Here’s where you’ll see the response data after making a request. It’s perfect for verifying your API call results and ensuring everything works as expected.',
      placement: 'top',
    },
  ]);


  const fetchGroups = async () => {
    try {
      // Using your API base URL; note that it ends with /api so we append /groups
      const response = await axios.get(`${API_BASE_URL}/groups`);
      // If your backend sorts them, you can simply store response.data
      setGroupOptions(response.data);
    } catch (error) {
      message.error('Failed to fetch API groups');
    }
  };
  
  useEffect(() => {
    fetchGroups();
  }, []);




// Add scroll listener to trigger a Joyride recalculation
useEffect(() => {
  const handleScroll = () => {
    // Force Joyride to recalculate positioning by toggling the state
    setTourSteps((steps) => [...steps]); // This will force Joyride to reposition
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


useEffect(() => {
  // Check if the tutorial has been completed before
  const hasCompletedTour = localStorage.getItem('hasCompletedTour');

  if (!hasCompletedTour) {
    // If not, start the tour with a delay
    const timer = setTimeout(() => {
      setTourActive(true);
    }, 3000); // 3000 ms = 3 seconds delay

    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }
}, []);

const handleJoyrideCallback = (data) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    // Mark tutorial as completed in localStorage
    localStorage.setItem('hasCompletedTour', 'true');
    setTourActive(false); // Stop the tour
  }
};


  // Called when user clicks “Run” in the RecipeList
  const handleRunRecipe = (recipe) => {
    setIsRunningRecipe(true);
    setRunningRecipeData(recipe);
  };

  // Called by RecipeRunner’s “Back” button
  const handleCloseRunner = () => {
    setIsRunningRecipe(false);
    setRunningRecipeData(null);
  };
  // Fetch docs (sorted by sortOrder) from backend
  const fetchDocumentationTopics = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/documentation`);
      // Sort by sortOrder ascending
      const sorted = response.data.sort((a, b) => a.sortOrder - b.sortOrder);
      setDocumentationTopics(sorted);
      setFilteredDocs(sorted);
      if (sorted.length > 0 && !selectedDoc) {
        // Select the first doc by default
        setSelectedDoc(sorted[0]);
        setHelpContent({ title: sorted[0].title, content: sorted[0].content });
      }
    } catch (error) {
      message.error("Failed to fetch documentation");
    }
  };

  // When help drawer opens, fetch documentation topics
  useEffect(() => {
    if (helpVisible) {
      fetchDocumentationTopics();
    }
  }, [helpVisible]);

  useEffect(() => {
    if (!docSearchQuery.trim()) {
      setFilteredDocs(documentationTopics);
    } else {
      const lowerCaseQuery = docSearchQuery.toLowerCase();
      const filtered = documentationTopics.filter(doc =>
        doc.title.toLowerCase().includes(lowerCaseQuery) ||
        doc.category.toLowerCase().includes(lowerCaseQuery) ||
        (doc.tags && doc.tags.some(tag => tag.toLowerCase().includes(lowerCaseQuery)))
      );
      setFilteredDocs(filtered);
    }
  }, [docSearchQuery, documentationTopics]);
  
  // When a document is selected, update helpContent
  const openDocumentation = (doc) => {
    setSelectedDoc(doc);
    setHelpContent({ title: doc.title, content: doc.content });
  };







const handleLoadRequestFromHistory = (historyItem) => {
  // Find the actual API config object by name
  let foundApi = null;
  for (const groupObj of apiGroups) {
    const found = groupObj.apis.find(a => a.name === historyItem.selectedApi);
    if (found) {
      foundApi = found;
      // Also set the group, since we know which group it belongs to
      setSelectedGroup(groupObj.group);
      break;
    }
  }

  if (!foundApi) {
    message.error('Unable to find the selected API in configurations.');
    return;
  }

  setSelectedApi(foundApi);
  setRequestParams(historyItem.requestData || {});
  setHistoryModalVisible(false);
};



 // Toggling recipe mode
 const toggleRecipeMode = () => {
  const newRecipeMode = !isRecipeMode;
  setIsRecipeMode(newRecipeMode);
  
  if (newRecipeMode) {
    setIsDevMode(false); // Deactivate Dev Mode
    // Reset Dev Mode related states
    setSelectedGroup(null);
    setSelectedApi(null);
    setIsAddingApi(false);
    setIsEditingApi(false);
    setEditingApiData(null);
    setCodeSnippet('');
    setResponse(null);
  }
  setIsRecipeMode(!isRecipeMode);
  setIsAddingRecipe(false);
  setIsEditingRecipe(false);
  setEditingRecipeData(null);
};

// Handler to create new recipe
const handleAddRecipe = () => {
  setIsAddingRecipe(true);
  setIsEditingRecipe(false);
  setEditingRecipeData(null);
};

// Handler to edit existing recipe
const handleEditRecipe = (recipeData) => {
  setIsEditingRecipe(true);
  setIsAddingRecipe(false);
  setEditingRecipeData(recipeData);
};

 // Handler after form submit
 const handleRecipeSubmit = async (values) => {
  try {
    if (isEditingRecipe && editingRecipeData?._id) {
      // Update existing
      await axios.put(`${API_BASE_URL}/recipes/${editingRecipeData._id}`, values);
      message.success('Recipe updated successfully');
    } else {
      // Create new
      await axios.post(`${API_BASE_URL}/recipes`, values);
      message.success('Recipe created successfully');
    }
    setIsAddingRecipe(false);
    setIsEditingRecipe(false);
    setEditingRecipeData(null);
  } catch (error) {
    console.error('Error saving recipe:', error);
    message.error('Failed to save recipe');
  }
};

// Handler to cancel recipe form
const handleRecipeCancel = () => {
  setIsAddingRecipe(false);
  setIsEditingRecipe(false);
  setEditingRecipeData(null);
};




  useEffect(() => {
    if (prevIsDevModeRef.current === true && isDevMode === false && !selectedGroup) {
      message.info('Please reselect the API group to see the latest updates.');
    }
    prevIsDevModeRef.current = isDevMode;
  }, [isDevMode, selectedGroup]);

  // Toggle function
  const toggleShowCredentials = () => {
    setShowCredentials(!showCredentials);
  };

  // Toggle function
  const toggleShowDevServerCredentials = () => {
    setShowDevServerCredentials(!showDevServerCredentials);
  };

  // Function to save devIp to local storage
  const handleSaveDevIp = () => {
    localStorage.setItem('devIp', devIp); // Save devIp to local storage
    message.success('Development Server URL saved'); // Notify user of the save action
  };

  const customTheme = {
    token: {
      fontFamily: 'Inter, sans-serif',
      colorPrimary: '#2A84FF', // Primary color for your buttons
      colorPrimaryHover: '#1C63CC', // Color when hovering over the button
      colorPrimaryActive: '#15499A', // Color when the button is active or clicked
      borderRadius: '6px', // Customize border radius for a rounded button look
      borderColor: '#2A84FF', // Border color for default and primary buttons
    },
  };

  // Fetch API Groups from the database when the component mounts
  useEffect(() => {
    fetchApiGroups();
  }, []);

  const fetchApiGroups = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/config`);
      // response.data is an array of API configurations
      // Group the APIs by their 'group' field
      const groupedApis = {};
      response.data.forEach((apiConfig) => {
        const groupName = apiConfig.group;
        if (!groupedApis[groupName]) {
          groupedApis[groupName] = [];
        }
        groupedApis[groupName].push(apiConfig);
      });
      // Sort each group by the 'groupSortOrder' field (or fallback to name)
      const apiGroupsArray = Object.keys(groupedApis).map((groupName) => ({
        group: groupName,
        apis: groupedApis[groupName].sort((a, b) => {
          // If groupSortOrder exists, sort by it. Otherwise, sort alphabetically by name.
          if (a.groupSortOrder != null && b.groupSortOrder != null) {
            return a.groupSortOrder - b.groupSortOrder;
          }
          return a.name.localeCompare(b.name);
        }),
      }));
      setApiGroups(apiGroupsArray);
    } catch (error) {
      message.error('Failed to fetch API groups');
    }
  };
  
  // Inside the App.js handlePreviewRequest function
  const handlePreviewRequest = (params) => {
    setRequestParams(params); // Store the request parameters for generating the code snippet
    generateRequestCodeSnippet(selectedApi.route, params, selectedLanguage, selectedApi); // Pass selectedApi
  };


  const showHelpDrawer = () => {
    setHelpVisible(true);
  };
  
  
// Save API Key and Bearer Token to localStorage if they are provided
const handleSaveCredentials = () => {
  if (!apiKey && !bearerToken) {
    message.error('Please enter at least one of API Key or Bearer Token');
    return;
  }
  
  if (apiKey) {
    localStorage.setItem('apiKey', apiKey);
  } else {
    localStorage.removeItem('apiKey'); // Remove from localStorage if left blank
  }
  
  if (bearerToken) {
    localStorage.setItem('bearerToken', bearerToken);
  } else {
    localStorage.removeItem('bearerToken'); // Remove from localStorage if left blank
  }

  message.success('API Credentials updated');
};

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
    setSelectedApi(null);
  };

  const handleApiChange = (value) => {
    const group = apiGroups.find((group) => group.group === selectedGroup);
    const apiDetails = group ? group.apis.find((api) => api.name === value) : null;

    setSelectedApi(apiDetails);
    setRequestParams({}); // Clear request parameters
    setCodeSnippet(''); // Clear the request code snippet
    setResponse(null); // Clear the response data
  };


  const toggleEditorSize = () => {
    setEditorExpanded(!editorExpanded);
  };

  const toggleRequestSize = () => {
    setIsRequestExpanded(!isRequestExpanded);
  };
  
  const saveRequestHistory = async (entry) => {
    try {
      await axios.post(`${API_BASE_URL}/history`, entry);
      console.log('Request history saved');
    } catch (error) {
      console.error('Failed to save request history:', error);
    }
  };
  


  const handleRunRequest = async (params) => {
    setRequestParams(params); // Store the request parameters for generating the code snippet
    try {
      if (selectedApi && selectedApi.route) {
        const requestData = { ...params }; // Prepare request data
        console.log('Making API call with:', requestData);
  
        const response = await apiService(selectedApi.route, requestData, selectedEnv, devIp, selectedApi); // Pass selectedApi
        console.log('API Service Response:', response);
  
        if (response && response.status >= 200 && response.status < 300) {
          console.log('Successful API Response:', response.data);
          setResponse(response.data); // Store the data to state if the call is successful
          generateRequestCodeSnippet(selectedApi.route, requestData, selectedLanguage, selectedApi); // Generate code snippet when request is run
          message.success('API request completed successfully!');
            // Construct the history entry
  const historyEntry = {
    memberName,
    authToken,
    apiKey: localStorage.getItem('apiKey') || '',
    bearerToken: localStorage.getItem('bearerToken') || '',
    selectedEnv,
    devIp,
    selectedGroup,
    selectedApi: selectedApi.name,
    requestData: requestData,
    timestamp: Date.now()
  };
  
  // Save the request history
  saveRequestHistory(historyEntry);
        } else {
          console.error('Unexpected API Response:', response);
          setResponse({ error: 'Unexpected API response received.' });
          message.error(`API request failed with status: ${response?.status} ${response?.statusText}`);
        }
      } else {
        setResponse({ error: 'API route not found. Please select a valid API.' });
        message.error('API route not found. Please select a valid API.');
      }
    } catch (error) {
      console.error('Error during API call:', error);
  
      // If there's a structured error response, use it directly
      if (error.data) {
        setResponse(error.data); // Set the response directly to the structured error response
      } else {
        // Fallback if no structured data is available
        setResponse({ error: 'Failed to fetch data' });
      }
  
      message.error(`Failed to fetch data: ${error.message}`);
    }
  };



  const generateRequestCodeSnippet = (route, params, language, selectedApi) => {
    if (!selectedApi) {
      setCodeSnippet('// Please select an API to generate code snippet.');
      return;
    }
  
    let snippet = '';
  
    // Retrieve API Key and Bearer Token from localStorage
    const apiKeyFromStorage = localStorage.getItem('apiKey') || 'your-api-key';
    const bearerTokenFromStorage = localStorage.getItem('bearerToken');
  
    // Extract parameters
    const { query = {}, path = {}, headers = {}, body = {}, formData = {}, attributes = [] } = params;
  
    // Replace path parameters in the route
    let url = SPECTER_ENV_URL +route;
    for (const [key, value] of Object.entries(path)) {
      url = url.replace(`:${key}`, encodeURIComponent(value));
    }
  
    // Append query parameters to the URL
    const queryString = new URLSearchParams(query).toString();
    if (queryString) {
      url += `?${queryString}`;
    }
  
    // Combine headers
    const allHeaders = {
      'Content-Type': 'application/json',
      'Api-Key': apiKeyFromStorage,
      ...(bearerTokenFromStorage ? { 'Authorization': `Bearer ${bearerTokenFromStorage}` } : {}),
      ...headers,
    };
  
    // Prepare request body or form data
    let requestBody = {};
    if (Object.keys(formData).length > 0) {
      requestBody = formData;
      // Handle form-data differently in code snippets
    } else if (Object.keys(body).length > 0) {
      requestBody = body;
    }
  
    // Include attributes if present
    if (attributes.length > 0) {
      if (Object.keys(requestBody).length === 0) {
        requestBody = {};
      }
      requestBody.attributes = attributes;
    }
  
    // Generate code snippet based on selected language
    switch (language) {
      case 'Node':
        if (Object.keys(formData).length > 0) {
          snippet = generateNodeFormDataSnippet(url, allHeaders, formData, selectedApi);
        } else {
          snippet = generateNodeSnippet(url, allHeaders, requestBody, selectedApi);
        }
        break;
      case 'Python':
        if (Object.keys(formData).length > 0) {
          snippet = generatePythonFormDataSnippet(url, allHeaders, formData, selectedApi);
        } else {
          snippet = generatePythonSnippet(url, allHeaders, requestBody, selectedApi);
        }
        break;
      case 'cURL':
        snippet = generateCurlSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'C#':
        snippet = generateCSharpSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Java':
        snippet = generateJavaSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Go':
        snippet = generateGoSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'PHP':
        snippet = generatePHPSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Ruby':
        snippet = generateRubySnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Swift':
        snippet = generateSwiftSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      default:
        snippet = '// Select a language to generate code';
    }
  
    setCodeSnippet(snippet); // Update the code snippet state
  };
  
  const generateNodeSnippet = (url, headers, requestBody, selectedApi) => {
    const method = selectedApi?.method?.toLowerCase() || 'get';
    return `const axios = require('axios');


axios.${selectedApi.method.toLowerCase()}('${url}', ${JSON.stringify(requestBody, null, 2)}, {
  headers: ${JSON.stringify(headers, null, 2)},
})
.then(response => console.log(response.data))
.catch(error => console.error('Error:', error));`;
};

const generateNodeFormDataSnippet = (url, headers, formData, selectedApi) => {
  return `const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');

const form = new FormData();
${Object.entries(formData)
    .map(([key, value]) => {
      if (value && value.filepath) {
        return `form.append('${key}', fs.createReadStream('${value.filepath}'));`;
      } else {
        return `form.append('${key}', '${value}');`;
      }
    })
    .join('\n')}

axios({
  method: '${selectedApi.method.toLowerCase()}',
  url: '${url}',
  headers: {
    ...form.getHeaders(),
    ${Object.entries(headers)
      .map(([key, value]) => `'${key}': '${value}'`)
      .join(',\n    ')}
  },
  data: form,
})
.then(response => console.log(response.data))
.catch(error => console.error('Error:', error));`;
};

const generatePythonSnippet = (url, headers, requestBody, selectedApi) => {
  return `import requests

url = '${url}'
headers = ${JSON.stringify(headers, null, 2)}

payload = ${JSON.stringify(requestBody, null, 2)}

try:
  response = requests.${selectedApi.method.toLowerCase()}(url, json=payload, headers=headers)
  response.raise_for_status()
  print(response.json())
except requests.exceptions.HTTPError as http_err:
  print(f"HTTP error occurred: {http_err}")
except Exception as err:
  print(f"Other error occurred: {err}")`;
};

const generatePythonFormDataSnippet = (url, headers, formData, selectedApi) => {
  return `import requests

url = '${url}'
headers = ${JSON.stringify(headers, null, 2)}

files = {
  ${Object.entries(formData)
    .map(([key, value]) => {
      if (value && value.filepath) {
        return `'${key}': open('${value.filepath}', 'rb')`;
      } else {
        return `'${key}': (None, '${value}')`;
      }
    })
    .join(',\n  ')}
}

try:
  response = requests.${selectedApi.method.toLowerCase()}(url, files=files, headers=headers)
  response.raise_for_status()
  print(response.json())
except requests.exceptions.HTTPError as http_err:
  print(f"HTTP error occurred: {http_err}")
except Exception as err:
  print(f"Other error occurred: {err}")`;
};

const generateCurlSnippet = (url, headers, requestBody, formData, selectedApi) => {
  const method = selectedApi?.method?.toUpperCase() || 'GET';

  let curlHeaders = '';
  for (const [key, value] of Object.entries(headers)) {
    curlHeaders += `-H '${key}: ${value}' \\\n`;
  }

  if (Object.keys(formData).length > 0) {
    let curlFormData = '';
    for (const [key, value] of Object.entries(formData)) {
      if (value && value.filepath) {
        curlFormData += `-F '${key}=@${value.filepath}' \\\n`;
      } else {
        curlFormData += `-F '${key}=${value}' \\\n`;
      }
    }
    return `curl -X ${method} '${url}' \\\n${curlHeaders}${curlFormData}`;
  } else {
    let curlData = `-d '${JSON.stringify(requestBody, null, 2)}'`;
    return `curl -X ${method} '${url}' \\\n${curlHeaders}${curlData}`;
  }
};

const generateCSharpSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `using System.Net.Http;
using System.Net.Http.Headers;
using System.Threading.Tasks;

var client = new HttpClient();
var url = "${url}";
var form = new MultipartFormDataContent();

${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `form.Add(new StreamContent(File.OpenRead("${value.filepath}")), "${key}", "${value.filename ?? 'file'}");`;
        } else {
          return `form.Add(new StringContent("${value}"), "${key}");`;
        }
      })
      .join('\n')}

${Object.entries(headers)
      .map(([key, value]) => `client.DefaultRequestHeaders.Add("${key}", "${value}");`)
      .join('\n')}

var response = await client.${selectedApi.method.toLowerCase()}Async(url, form);
var responseBody = await response.Content.ReadAsStringAsync();
Console.WriteLine(responseBody);`;
  } else {
    return `using System.Net.Http;
using System.Text;
using Newtonsoft.Json;

var client = new HttpClient();
var url = "${url}";
var requestBody = JsonConvert.SerializeObject(${JSON.stringify(requestBody, null, 2)});
var content = new StringContent(requestBody, Encoding.UTF8, "application/json");
${Object.entries(headers)
      .map(([key, value]) => `client.DefaultRequestHeaders.Add("${key}", "${value}");`)
      .join('\n')}
var response = await client.${selectedApi.method.toLowerCase()}Async(url, content);
var responseBody = await response.Content.ReadAsStringAsync();
Console.WriteLine(responseBody);`;
  }
};


const generateGoSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `package main

import (
  "bytes"
  "fmt"
  "mime/multipart"
  "net/http"
  "os"
)

func main() {
  url := "${url}"
  var b bytes.Buffer
  w := multipart.NewWriter(&b)

  ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `file, _ := os.Open("${value.filepath}")
  defer file.Close()
  fw, _ := w.CreateFormFile("${key}", "${value.filename ?? 'file'}")
  io.Copy(fw, file)`;
        } else {
          return `w.WriteField("${key}", "${value}")`;
        }
      })
      .join('\n  ')}

  w.Close()

  req, _ := http.NewRequest("${selectedApi.method.toUpperCase()}", url, &b)
  req.Header.Set("Content-Type", w.FormDataContentType())
  ${Object.entries(headers)
      .map(([key, value]) => `req.Header.Set("${key}", "${value}")`)
      .join('\n  ')}

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    panic(err)
  }
  defer resp.Body.Close()
  fmt.Println("Response status:", resp.Status)
}`;
  } else {
    return `package main

import (
  "bytes"
  "fmt"
  "net/http"
)

func main() {
  url := "${url}"
  var jsonStr = []byte(\`${JSON.stringify(requestBody, null, 2)}\`)

  req, err := http.NewRequest("${selectedApi.method.toUpperCase()}", url, bytes.NewBuffer(jsonStr))
  req.Header.Set("Content-Type", "application/json")
  ${Object.entries(headers)
      .map(([key, value]) => `req.Header.Set("${key}", "${value}")`)
      .join('\n  ')}

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    panic(err)
  }
  defer resp.Body.Close()
  fmt.Println("Response status:", resp.Status)
}`;
  }
};

const generatePHPSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "${url}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "${selectedApi.method.toUpperCase()}",
  CURLOPT_POSTFIELDS => http_build_query(${JSON.stringify(formData, null, 2)}),
  CURLOPT_HTTPHEADER => array(
    ${Object.entries(headers)
      .map(([key, value]) => `"${key}: ${value}"`)
      .join(',\n    ')}
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #: " . $err;
} else {
  echo $response;
}
?>`;
  } else {
    return `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "${url}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "${selectedApi.method.toUpperCase()}",
  CURLOPT_POSTFIELDS => json_encode(${JSON.stringify(requestBody, null, 2)}),
  CURLOPT_HTTPHEADER => array(
    "Content-Type: application/json",
    ${Object.entries(headers)
      .map(([key, value]) => `"${key}: ${value}"`)
      .join(',\n    ')}
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #: " . $err;
} else {
  echo $response;
}
?>`;
  }
};

const generateRubySnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `require 'net/http'
require 'uri'

uri = URI.parse('${url}')
request = Net::HTTP::${selectedApi.method.charAt(0).toUpperCase() + selectedApi.method.slice(1).toLowerCase()}.new(uri)
${Object.entries(headers)
      .map(([key, value]) => `request['${key}'] = '${value}'`)
      .join('\n')}

form_data = [
  ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `['${key}', File.open('${value.filepath}')]`;
        } else {
          return `['${key}', '${value}']`;
        }
      })
      .join(',\n  ')}
]

request.set_form form_data, 'multipart/form-data'

req_options = {
  use_ssl: uri.scheme == 'https',
}

response = Net::HTTP.start(uri.hostname, uri.port, req_options) do |http|
  http.request(request)
end

puts response.body`;
  } else {
    return `require 'net/http'
require 'uri'
require 'json'

uri = URI.parse('${url}')
request = Net::HTTP::${selectedApi.method.charAt(0).toUpperCase() + selectedApi.method.slice(1).toLowerCase()}.new(uri)
${Object.entries(headers)
      .map(([key, value]) => `request['${key}'] = '${value}'`)
      .join('\n')}
request.content_type = 'application/json'
request.body = ${JSON.stringify(requestBody, null, 2)}.to_json

req_options = {
  use_ssl: uri.scheme == 'https',
}

response = Net::HTTP.start(uri.hostname, uri.port, req_options) do |http|
  http.request(request)
end

puts response.body`;
  }
};

const generateSwiftSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `import Foundation

let url = URL(string: "${url}")!
var request = URLRequest(url: url)
request.httpMethod = "${selectedApi.method.toUpperCase()}"

${Object.entries(headers)
      .map(([key, value]) => `request.addValue("${value}", forHTTPHeaderField: "${key}")`)
      .join('\n')}

let boundary = UUID().uuidString
request.setValue("multipart/form-data; boundary=\\(boundary)", forHTTPHeaderField: "Content-Type")

var body = Data()

${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `let fileData = try Data(contentsOf: URL(fileURLWithPath: "${value.filepath}"))
body.append("--\\(boundary)\\r\\n".data(using: .utf8)!)
body.append("Content-Disposition: form-data; name=\\"${key}\\"; filename=\\"${value.filename ?? 'file'}\\"\\r\\n".data(using: .utf8)!)
body.append("Content-Type: application/octet-stream\\r\\n\\r\\n".data(using: .utf8)!)
body.append(fileData)
body.append("\\r\\n".data(using: .utf8)!)`;
        } else {
          return `body.append("--\\(boundary)\\r\\n".data(using: .utf8)!)
body.append("Content-Disposition: form-data; name=\\"${key}\\"\\r\\n\\r\\n".data(using: .utf8)!)
body.append("${value}\\r\\n".data(using: .utf8)!)`;
        }
      })
      .join('\n')}

body.append("--\\(boundary)--\\r\\n".data(using: .utf8)!)
request.httpBody = body

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  guard let data = data, error == nil else { return }
  print(String(data: data, encoding: .utf8)!)
}
task.resume()`;
  } else {
    return `import Foundation

let url = URL(string: "${url}")!
var request = URLRequest(url: url)
request.httpMethod = "${selectedApi.method.toUpperCase()}"

${Object.entries(headers)
      .map(([key, value]) => `request.addValue("${value}", forHTTPHeaderField: "${key}")`)
      .join('\n')}

let json: [String: Any] = ${JSON.stringify(requestBody, null, 2)}
let jsonData = try? JSONSerialization.data(withJSONObject: json)

request.httpBody = jsonData

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  guard let data = data, error == nil else { return }
  print(String(data: data, encoding: .utf8)!)
}
task.resume()`;
  }
};

const generateJavaSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `import java.io.File;
import java.io.IOException;
import okhttp3.*;

public class Main {
  public static void main(String[] args) throws IOException {
    OkHttpClient client = new OkHttpClient();

    MultipartBody.Builder multipartBuilder = new MultipartBody.Builder().setType(MultipartBody.FORM);

    ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `multipartBuilder.addFormDataPart("${key}", "${value.filename ?? 'file'}",
            RequestBody.create(new File("${value.filepath}"), MediaType.parse("application/octet-stream")));`;
        } else {
          return `multipartBuilder.addFormDataPart("${key}", "${value}");`;
        }
      })
      .join('\n    ')}

    RequestBody requestBody = multipartBuilder.build();

    Request request = new Request.Builder()
      .url("${url}")
      .method("${selectedApi.method.toUpperCase()}", requestBody)
      ${Object.entries(headers)
        .map(([key, value]) => `.addHeader("${key}", "${value}")`)
        .join('\n      ')}
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}`;
  } else {
    return `import java.io.IOException;
import okhttp3.*;

public class Main {
  public static void main(String[] args) throws IOException {
    OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(${JSON.stringify(requestBody, null, 2)}, mediaType);

    Request request = new Request.Builder()
      .url("${url}")
      .method("${selectedApi.method.toUpperCase()}", body)
      ${Object.entries(headers)
        .map(([key, value]) => `.addHeader("${key}", "${value}")`)
        .join('\n      ')}
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}`;
  }
};

// Define onSelectOrg using the state updater
const onSelectOrg = (org) => {
  setSelectedOrg(org);
};


useEffect(() => {
  const storedOrgId = localStorage.getItem('selectedOrgId');
  if (storedOrgId && orgs.length > 0 && !selectedOrg) {
    const savedOrg = orgs.find(o => o.id.toString() === storedOrgId);
    if (savedOrg) {
      onSelectOrg(savedOrg);
    }
  }
}, [orgs, selectedOrg, onSelectOrg]);

 // Build the content for the popover
 const orgContent = (
  <List
    dataSource={orgs}
    renderItem={(org) => (
      <List.Item
        className="org-list-item"
        onClick={() => {
          onSelectOrg(org);
          localStorage.setItem('selectedOrgId', org.id);
        }}
        style={{ cursor: 'pointer' }}
      >
        <List.Item.Meta
          avatar={<Avatar style={{ backgroundColor: '#444' }}>{org.name.charAt(0).toUpperCase()}</Avatar>}
          title={org.name}
        />
      </List.Item>
    )}
  />
);


const handleLanguageChange = (value) => {
  setSelectedLanguage(value);
  generateRequestCodeSnippet(selectedApi?.route, requestParams, value, selectedApi); // Pass selectedApi
};

const toggleDevMode = () => {
  const newDevMode = !isDevMode;
  setIsDevMode(newDevMode);
  
  if (newDevMode) {
    setIsRecipeMode(false); // Deactivate Recipe Mode
    // Reset Recipe-related states
    setIsAddingRecipe(false);
    setIsEditingRecipe(false);
    setEditingRecipeData(null);
  }

  setIsDevMode(!isDevMode); // Toggle between modes
  setSelectedGroup(null); // Reset the selected group
  setSelectedApi(null); // Reset the selected API
  setIsAddingApi(false); // Reset adding API state
  setIsEditingApi(false); // Reset editing API state
  setEditingApiData(null); // Clear editing API data
  setCodeSnippet(''); // Clear the request code snippet
  setResponse(null); // Clear the response data
};

const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  message.success('Copied to clipboard');
};

// Function to handle adding or editing an API
const handleApiSubmit = (values) => {
  if (isEditingApi) {
    // Update existing API
    axios
      .put(`${API_BASE_URL}/config/${editingApiData._id}`, values)
      .then((response) => {
        message.success('API updated successfully');
        fetchApiGroups(); // Refresh the API groups
        // Reset the states
        setIsAddingApi(false);
        setIsEditingApi(false);
        setEditingApiData(null);
      })
      .catch((error) => {
        console.error('Error updating API:', error);
        message.error('Failed to update API');
      });
  } else {
    // Add new API
    axios
      .post(`${API_BASE_URL}/config`, values)
      .then((response) => {
        message.success('API added successfully');
        fetchApiGroups(); // Refresh the API groups
        // Reset the states
        setIsAddingApi(false);
        setIsEditingApi(false);
        setEditingApiData(null);
      })
      .catch((error) => {
        console.error('Error adding API:', error);
        message.error('Failed to add API');
      });
  }
};

useEffect(() => {
  const storedKeys = localStorage.getItem('orgApiKeys');
  if (storedKeys) {
    setOrgApiKeys(JSON.parse(storedKeys));
  }
}, []);



 // On mount, load stored orgs from localStorage
 useEffect(() => {
  const storedOrgs = localStorage.getItem('orgs');
  if (storedOrgs) {
    setOrgs(JSON.parse(storedOrgs));
  }
}, []);
async function handleLogin(values) {
  setIsLoading(true);
  try {
    const headers = { 'Content-Type': 'application/json',    'env': selectedEnv,
    ...(selectedEnv === 'development' && devIp && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
  };

    const response = await fetch(
      'https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/member/sign-in',
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ email: values.email, password: values.password }),
      }
    );
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Login failed');
    const memberDetails = data.data.memberDetails;
    if (!memberDetails || !memberDetails.authToken)
      throw new Error('No authToken returned');

    setAuthToken(memberDetails.authToken);
    setMemberName(memberDetails.name);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('memberName', memberDetails.name);
    localStorage.setItem('authToken', memberDetails.authToken);

    // Process organisations and extract API keys:
    const orgArr = memberDetails.organisations || [];
    if (orgArr.length > 0) {
      setOrgs(orgArr);
      localStorage.setItem('orgs', JSON.stringify(orgArr));
      // Build an object mapping each org's id to its API keys (assumed to be in the "api_keys" field)
      const keysMap = orgArr.reduce((acc, org) => {
        acc[org.id] = org.api_keys;
        return acc;
      }, {});
      setOrgApiKeys(keysMap);


      if (orgArr.length > 1) {
        setLoginStep(1);
        message.success('Login successful. Please select an org next.');
      } else if (orgArr.length === 1) {
        const onlyOrg = orgArr[0];
        setSelectedOrg(onlyOrg);
        localStorage.setItem('selectedOrgId', onlyOrg.id);
        finalizeLogin(onlyOrg);
      }
    } else {
      throw new Error('No organisations found for your account.');
    }
  } catch (error) {
    message.error(`Login failed: ${error.message}`);
  } finally {
    setIsLoading(false);
  }
}

// Similarly, update your handleGoogleLogin as follows:
const handleGoogleLogin = useGoogleLogin({
  onSuccess: async (tokenResponse) => {
    setIsLoading(true);
    try {
      const accessToken = tokenResponse.access_token;
      const userInfoResponse = await fetch(
        'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      const userInfo = await userInfoResponse.json();
      const { email, id: googleId } = userInfo;
      const headers = {
        'Content-Type': 'application/json',
        'env': selectedEnv,
        ...(selectedEnv === 'development' && devIp && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
      };
      const payload = { email: email || '', password: null, googleId };
      const res = await fetch(
        'https://specter-playground-proxy-server.azurewebsites.net/api/admin/v1/member/sign-in',
        {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        }
      );
      const data = await res.json();
      if (!res.ok) throw new Error(data.message || 'Google login failed');
      const memberDetails = data.data.memberDetails;
      if (!memberDetails?.authToken)
        throw new Error('No authToken returned from Google sign-in');

      setAuthToken(memberDetails.authToken);
      setMemberName(memberDetails.name);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('memberName', memberDetails.name);
      localStorage.setItem('authToken', memberDetails.authToken);

      // Process organisations and extract API keys:
      const orgArr = memberDetails.organisations || [];
      if (orgArr.length > 0) {
        setOrgs(orgArr);
        localStorage.setItem('orgs', JSON.stringify(orgArr));
        // Build the keys mapping:
        const keysMap = orgArr.reduce((acc, org) => {
          acc[org.id] = org.api_keys;
          return acc;
        }, {});
        setOrgApiKeys(keysMap);
              // After building keysMap in handleLogin:
localStorage.setItem('orgApiKeys', JSON.stringify(keysMap));
setOrgApiKeys(keysMap);


        if (orgArr.length > 1) {
          setLoginStep(1);
          message.success('Google sign-in success. Select org next!');
        } else if (orgArr.length === 1) {
          const onlyOrg = orgArr[0];
          setSelectedOrg(onlyOrg);
          localStorage.setItem('selectedOrgId', onlyOrg.id);
          finalizeLogin(onlyOrg);
        }
      } else {
        throw new Error('No organisations for this Google-linked account');
      }
    } catch (error) {
      message.error(`Google login failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  },
  onError: () => {
    message.error('Google login failed');
  },
});

  function renderOrgSelectionStep() {
    return (
      <div>
        <Typography.Title level={5}>Select Organisation</Typography.Title>
        {orgs.map((org) => (
          <Card key={org.id} style={{ marginBottom: 8, cursor: 'pointer' }} onClick={() => {
            setSelectedOrg(org);
            localStorage.setItem('selectedOrgId', org.id);
            finalizeLogin(org);
          }}>
            {org.name}
          </Card>
        ))}
      </div>
    );
  }

  function finalizeLogin(org) {
    localStorage.setItem('selectedOrgId', org.id);
    setIsLoggedIn(true);
    setLoginModalVisible(false);
    message.success(`Using org: ${org.name}`);
  }

// Flatten all groups/APIs into one array
const allFlattenedApis = useMemo(() => {
  return apiGroups.flatMap((groupObj) =>
    groupObj.apis.map((apiObj) => ({
      label: `${groupObj.group} / ${apiObj.name}`,   // what shows in the dropdown
      value: `${groupObj.group}###${apiObj.name}`,   // a unique string to identify
      groupName: groupObj.group,                     // store group
      apiConfig: apiObj,                              // the entire API config
      tags: apiObj.tags || []                        // for advanced filtering
    }))
  );
}, [apiGroups]);

const capitalizeHeader = (header) =>
  header
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');



// This is called when user picks an item from the new search box
const handleSearchSelection = (value) => {
  // 'value' is something like "Player###ListPlayers"
  const [groupName, apiName] = value.split('###');

  // Set the group
  setSelectedGroup(groupName);

  // Find the actual API object in that group
  const groupObj = apiGroups.find((g) => g.group === groupName);
  if (!groupObj) return;

  const foundApi = groupObj.apis.find((api) => api.name === apiName);
  if (!foundApi) return;

  // Now set the selected API
  setSelectedApi(foundApi);
};

useEffect(() => {
  // If selectedGroup & selectedApi change, update the "value" of the search box
  if (selectedGroup && selectedApi) {
    const newValue = `${selectedGroup}###${selectedApi.name}`;
    setSearchBoxValue(newValue);
  }
}, [selectedGroup, selectedApi]);




const handleDocSwitch = (newApiName) => {
  let foundApi = null;
  for (const groupObj of apiGroups) {
    foundApi = groupObj.apis.find((api) => api.name === newApiName);
    if (foundApi) break;
  }
  if (!foundApi) {
    message.error('API not found for ' + newApiName);
    return;
  }
  setSelectedApi(foundApi);
};





return (
  <>
    {announcementVisible && (
      <Alert
        message="Introducing the Specter AI Assistant Beta! Log in to your account to unlock our cutting-edge AI feature and supercharge your sandbox experience."
        type="info"
        banner
        closable
        onClose={() => setAnnouncementVisible(false)}
        style={{ textAlign: 'center' }}  // Optional: center the text to match your UI style
      />
    )}

  <ConfigProvider theme={customTheme}>
 <Joyride
  steps={tourSteps}
  continuous
  locale={{   last: "Let's Start Exploring!", }}
  showSkipButton
  run={tourActive}
  styles={{
    options: {
      arrowColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      overlayColor: 'rgba(0, 0, 0, 0.6)',
      primaryColor: '#2A84FF',
      textColor: '#333333',
      zIndex: 1000,
    },
    tooltip: {
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.15)',
      position: 'relative',
    },
    tooltipContainer: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '1.5',
    },
    buttonNext: {
      backgroundColor: '#2A84FF',
      color: '#FFFFFF',
      fontSize: '14px',
      padding: '8px 16px',
      borderRadius: '4px',
      border: 'none',
      boxShadow: 'none',
    },
    buttonBack: {
      color: '#555555',
      fontSize: '14px',
      padding: '8px 16px',
    },
    buttonSkip: {
      color: '#888888',
      fontSize: '14px',
      padding: '8px 16px',
      borderRadius: '6px',
      border: '1px solid #e0e0e0',
    },
    tooltipTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '8px',
      color: '#333333',
    },
    tooltipContent: {
      paddingBottom: '12px',
    },
    spotlight: {
      borderRadius: '8px',
      boxShadow: 'none', // No outline
    },
    arrow: {
      display: 'none', // Disable default arrow
    },
  }}
  callback={handleJoyrideCallback}
/>


    <Layout style={{ minHeight: '100vh', backgroundColor: '#f7f9fc' }}>
    <Header
  style={{
    height: '50px',
    backgroundColor: '#272B30',
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  {/* Make this entire left section clickable */}
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      cursor: 'pointer', // so the user sees it’s clickable
    }}
    onClick={() => {
      // If currently in Recipe Mode, toggle back
      if (isRecipeMode) {
        toggleRecipeMode();
      }
      // Else if currently in Dev Mode, toggle back
      else if (isDevMode) {
        toggleDevMode();
      }
      // Otherwise, do nothing or navigate somewhere else if desired
    }}
  >
    <img
      src="/images/specter_ghost.png"
      alt="Specter Logo"
      style={{ width: '24px', height: '24px' }}
    />
    <Title level={4} style={{ color: '#ffffff', margin: 0 }}>
      Specter API Sandbox
    </Title>
  </div>
  <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
  {!isProductionMode && (
  <Select
    defaultValue="console"
    style={{ width: 150, marginLeft: '8px' }}
    onChange={(value) => setSelectedEnv(value)}
  >
    <Option value="development">Development</Option>
    <Option value="staging">Staging</Option>
    <Option value="demo">Demo</Option>
    <Option value="console">Console</Option>
  </Select>
)}

       {/* Existing Dropdown (unchanged) */}
       <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button type="text" icon={<MenuOutlined style={{ fontSize: '20px', color: '#ffffff' }} />} />
    </Dropdown>
    {/* Display greeting or login button */}

<Dropdown
  overlay={
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        columnGap: '12px',
        rowGap: '20px',
        padding: '20px 12px 12px 12px',
        width: '240px',
        backgroundColor: '#ffffff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      }}
    >
      {gridMenuItems.map((item) => (
  <div
  key={item.key}
  className="grid-item-wrapper"
  style={{
    textAlign: 'center',
    padding: '12px', // Increased padding for more hover area
    borderRadius: '6px', // Slightly increased border radius
  }}
>
          <Button
            type="text"
         
            onClick={() => {
              item.onClick(); // Execute the grid item's action
              setGridMenuVisible(false); // Then close the dropdown
            }}
            className="grid-item-button"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
              width: '100%',
              color: 'inherit',
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
            }}
          >
         {React.cloneElement(item.icon, { style: { fontSize: '24px', color: item.color } })}
            <span
              style={{
                fontSize: '10px',
                marginTop: '4px',
                lineHeight: '1',
                textDecoration: 'none',
                display: 'block',
                width: '100%',
              }}
            >
              {item.label}
            </span>
          </Button>
        </div>
      ))}
    </div>
  }
  trigger={['click']}
  placement="bottomRight"
  visible={gridMenuVisible}
  onVisibleChange={(flag) => setGridMenuVisible(flag)}
>
  <Button
    type="text"
    icon={<AppstoreOutlined style={{ fontSize: '20px', color: '#ffffff' }} />}
  />
</Dropdown>
<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
  {/* Existing grid menu and environment select components here */}

  {!isLoggedIn ? (
    <Button type="default" onClick={() => setLoginModalVisible(true)}>Log in</Button>
  ) : (
    <UserDropdown
      memberName={memberName}
      currentOrg={selectedOrg}
      onSwitchOrg={() => {
        setLoginModalVisible(true);
        setLoginStep(1);
      }}
      onLogout={handleLogout}
    />
  )}

</div>
        
          </div>

</Header>


    
<Modal
  title="Log in with your Specter Account"
  visible={loginModalVisible}
  onCancel={() => setLoginModalVisible(false)}
  footer={null}
>
  {loginStep === 0 && (
    <Form layout="vertical" onFinish={handleLogin}>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: 'Please enter your email' }]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: 'Please enter your password' }]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ width: '100%' }}
        >
          Log in
        </Button>
      </Form.Item>

      <Divider>or</Divider>
      <Button
        type="default"
        onClick={() => handleGoogleLogin()} // triggers the google flow
        icon={
          <img
            src={`${process.env.PUBLIC_URL}/images/google2.svg`}
            alt="Google"
            style={{
              width: '20px',
              height: '20px',
              marginRight: '4px'
            }}
          />
        }
        style={{ width: '100%' }}
      >
        Sign in with Google
      </Button>
    </Form>
  )}

  {loginStep === 1 && renderOrgSelectionStep()}
</Modal>

          
          {(!isDevMode && !isRecipeMode) && (
            <Card
  bordered={false}
  style={{
    backgroundColor: '#f0f2f5',
    padding: '16px 20px',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  }}
>
  <Row gutter={[16, 0]} align="middle">
    <Col xs={24} lg={6}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <ApiOutlined style={{ fontSize: '22px', color: '#2A84FF', marginRight: '8px' }} />
        <Title level={4} style={{ margin: 0 }}>
          Build Next-Gen Gaming Experiences
        </Title>
      </div>
      <Paragraph style={{ fontSize: '13px', color: '#595959', marginBottom: '0', lineHeight: '1.5' }}>
        Explore our suite of powerful APIs designed to create immersive, scalable gaming worlds that keep players engaged!
      </Paragraph>
    </Col>
    
    <Col xs={24} lg={18}>
      <Row gutter={[8, 8]}>
        {[
          { name: "App", color: "#5DADE2" },
          { name: "Auth", color: "#48C9B0" },
          { name: "Account", color: "#F5B041" },
          { name: "Player/Me", color: "#EC7063" },
          { name: "Player/Others", color: "#AF7AC5" },
          { name: "Wallet", color: "#5DADE2" },
          { name: "Progression", color: "#48C9B0" },
          { name: "Inventory", color: "#F5B041" },
          { name: "Stores", color: "#EC7063" },
          { name: "Matches", color: "#AF7AC5" },
          { name: "Leaderboards", color: "#5DADE2" },
          { name: "Competitions", color: "#48C9B0" },
          { name: "Achievements", color: "#F5B041" },
          { name: "Events", color: "#EC7063" },
          { name: "Live Ops", color: "#AF7AC5" },
          { name: "Real Money Gaming", color: "#5DADE2" }
        ].map((category, index) => (
          <Col key={index} xs={12} sm={8} md={6} lg={4}>
            <div 
              style={{ 
                padding: '8px 10px', 
                background: 'white', 
                borderRadius: '6px', 
                borderLeft: `3px solid ${category.color}`,
                cursor: 'pointer',
                transition: 'all 0.2s',
                boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                display: 'flex',
                alignItems: 'center',
                height: '36px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 2px 6px rgba(0,0,0,0.1)';
                e.currentTarget.style.transform = 'translateY(-1px)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              <Text style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{category.name}</Text>
            </div>
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
</Card>
      )}

<Content style={{ padding: '20px 40px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
{isRecipeMode ? (
        // If we are in "Manage Recipes" mode:
        <>
          {isRunningRecipe ? (
            // *** Show the Runner ***
            <RecipeRunner
              recipe={runningRecipeData}
              onClose={handleCloseRunner}
              selectedEnv={selectedEnv} // Pass selectedEnv
              devIp={devIp}             // Pass devIp
            />
          ) : isAddingRecipe || isEditingRecipe ? (
            // *** Show the Form ***
            <RecipeForm
              initialValues={editingRecipeData}
              isEditMode={isEditingRecipe}
              onSubmit={handleRecipeSubmit}
              onCancel={handleRecipeCancel}
            />
          ) : (
            // *** Show the List ***
            <RecipeList
              onAddRecipe={handleAddRecipe}
              onEditRecipe={handleEditRecipe}
              onRunRecipe={handleRunRecipe} // <== Pass it in
            />
          )}
        </>
  ) : isDevMode ? (
    /* ----------  DEV MODE (MANAGE APIs)  ---------- */
    <>
      {isAddingApi || isEditingApi ? (
        // Show the ApiConfigForm when adding or editing an API
        <ApiConfigForm
          initialValues={editingApiData}
          isEditMode={isEditingApi}
          onSubmit={handleApiSubmit}
          onCancel={() => {
            // Handle form cancellation
            setIsAddingApi(false);
            setIsEditingApi(false);
            setEditingApiData(null);
          }}
        />
      ) : (
        // Show the API List when not adding or editing
        <ApiList
          onAddApi={() => setIsAddingApi(true)}
          onEditApi={(apiData) => {
            setIsEditingApi(true);
            setEditingApiData(apiData);
          }}
        />
      )}
    </>
  ) : (
    /* ----------  NORMAL USER MODE  ---------- */
    <>
     {selectedEnv === 'development' && (
  <Card
    title="Development Server URL"
    bordered={false}
    style={{ marginBottom: '20px' }}
    extra={
      <Switch
        checked={showDevServerCredentials}
        onChange={() => setShowDevServerCredentials(!showDevServerCredentials)}
        checkedChildren="Hide"
        unCheckedChildren="Show"
      />
    }
  >
    {showDevServerCredentials && (
      <Form layout="vertical">
        <Form.Item label="Enter your Development Server URL">
          <Input
            placeholder="Enter Development Server URL"
            value={devIp}
            onChange={(e) => setDevIp(e.target.value)}
          />
        </Form.Item>
        <Button type="primary" onClick={handleSaveDevIp}>
          Save
        </Button>
      </Form>
    )}
  </Card>
)}

<Card
  className="api-credentials"
  title="API Credentials"
  bordered={false}
  style={{ marginBottom: '20px' }}
  extra={
    <Switch
      checked={showCredentials}
      onChange={() => setShowCredentials(!showCredentials)}
      checkedChildren="Hide"
      unCheckedChildren="Show"
    />
  }
>
  {showCredentials && (
    <>
      <p style={{ color: '#888', marginTop: '-8px', marginBottom: '10px' }}>
        Enter your API key for your application environment...
      </p>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  API Key&nbsp;
                  <Tooltip
                    title="Used to authenticate API requests from this app. Keep it secure."
                    placement="right"
                    color="#f5f5f5"
                    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                  &nbsp;
                  <span
                    onClick={() => {
                      if (!isLoggedIn) {
                        message.info('Please log in to select your API key.');
                      } else {
                        setShowApiKeySelector(true);
                      }
                    }}
                    style={{ color: '#1890ff', cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Select your API key
                  </span>
                </span>
              }
            >
              <Input
                placeholder="Enter API Key"
                className="api-key-input"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                suffix={<CopyOutlined onClick={() => handleCopyToClipboard(apiKey)} />}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Bearer Token&nbsp;
                  <Tooltip
                    title="Grants access for the player's session. Keep it secure."
                    placement="right"
                    color="#f5f5f5"
                    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
            >
              <Input
                placeholder="Enter Bearer Token"
                value={bearerToken}
                onChange={(e) => setBearerToken(e.target.value)}
                suffix={<CopyOutlined onClick={() => handleCopyToClipboard(bearerToken)} />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" onClick={handleSaveCredentials}>
          Save
        </Button>
      </Form>
    </>
  )}
</Card>

      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ flex: 1 }}>
          <Card title="API Selection" bordered={false} style={{ marginBottom: '20px' }}>
            <p style={{ color: '#888', marginTop: '-8px', marginBottom: '10px' }}>
              Select an API category and a specific API endpoint to interact with.
            </p>
            <Select
  showSearch
  style={{ width: '100%' }}
  placeholder="Search by API name or keywords..."
  // Your custom filter
  onChange={handleSearchSelection} 
  filterOption={(inputValue, option) => {
    // Make sure option isn't undefined
    if (!option) return false;

    // In AntD v4, option might look like { value, children, label, ... } 
    // depending on how you set it. If you assigned `tags` as a prop,
    // you can retrieve them by `option.tags` (or `option.props.tags` in some versions).
    // So do safe checks:

    const label = option.label || '';        // or option.children
    const tags  = option.tags  || [];

    const searchText = inputValue.toLowerCase();
    const labelMatch = label.toLowerCase().includes(searchText);
    const tagMatch   = tags.some((tag) => tag.toLowerCase().includes(searchText));

    return labelMatch || tagMatch;
  }}
>
  {allFlattenedApis.map((item) => (
    <Select.Option
      key={item.value}
      // You MUST define 'label' and 'tags' at the Option-level if you want them in filterOption
      label={item.label}
      tags={item.tags}
      value={item.value}
    >
      {item.label}
    </Select.Option>
  ))}
</Select>


<Select
  className="api-group-select"
  placeholder="Select API Category"
  style={{ width: '100%', marginTop: '10px' }}
  onChange={handleGroupChange}
  value={selectedGroup}
>
  {groupOptions.map((group) => (
    <Option key={group.name} value={group.name}>
      <Tooltip title={group.description}  placement="right"  color="#f5f5f5"
            overlayInnerStyle={{ color: '#000', fontSize: '14px' }}>
        <span>{group.name}</span>
      </Tooltip>
    </Option>
  ))}
</Select>


            <Select
              className="api-select"
              placeholder="Select API"
              style={{ width: '100%', marginTop: '10px' }}
              onChange={handleApiChange}
              disabled={!selectedGroup}
              value={selectedApi ? selectedApi.name : undefined}
            >
              {selectedGroup &&
                apiGroups
                  .find((group) => group.group === selectedGroup)
                  ?.apis?.map((api) => (
                    <Option key={api.name} value={api.name}>
                      {api.name}
                    </Option>
                  ))}
            </Select>

            {selectedApi && selectedApi.description && (
              <div
                style={{
                  marginTop: '15px',
                  backgroundColor: '#f0f2f5',
                  padding: '10px',
                  borderRadius: '6px',
                }}
              >
                <Paragraph style={{ fontSize: '14px', color: '#595959', marginBottom: '0' }}>
                  {selectedApi.description}
                </Paragraph>
   
      {/* Tags */}
      {selectedApi.tags && selectedApi.tags.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          {selectedApi.tags.map((tag, index) => (
            <Tag key={tag} style={{ marginBottom: '4px' }} color={getTagColor(index)}>
              {tag}
            </Tag>
          ))}
        </div>
      )}

      {/* Required Headers */}
      {selectedApi.requiredHeaders && selectedApi.requiredHeaders.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          <Text strong>Required Headers: </Text>
          {selectedApi.requiredHeaders.map((hdr) => (
  <Tooltip key={hdr.name} title={hdr.description || 'No description'} color="#f5f5f5" overlayInnerStyle={{ color: '#000', fontSize: '14px' }}>
    <Tag color="volcano" style={{ marginBottom: '4px' }}>
      {capitalizeHeader(hdr.name)}
    </Tag>
  </Tooltip>
))}

        </div>
      )}

      <Divider style={{ margin: '20px 0' }} />

      {/* Action Buttons */}
      <Row justify="end" gutter={16}>
        {selectedApi.longDescription && (
          <Col>
            <Button
              type="default"
              onClick={() => setDocsDrawerVisible(true)}
              style={{ borderRadius: '6px' }}
            >
             API Reference
            </Button>
          </Col>
        )}
        {selectedApi.examples && selectedApi.examples.length > 0 && (
          <Col>
            <Button
              type="default"
              onClick={() => setExamplesDrawerVisible(true)}
              style={{ borderRadius: '6px' }}
            >
             Usage Examples
            </Button>
          </Col>
        )}
      </Row>
    </div>
  )}

      </Card>


      <Drawer
  placement="right"
  visible={docsDrawerVisible}
  onClose={() => {
    setDocsDrawerVisible(false);
    setApiRefDrawerFullscreen(false);
  }}
  width={apiRefDrawerFullscreen ? '100%' : 800}
  style={apiRefDrawerFullscreen ? { top: 0, margin: 0, padding: 0, zIndex: 9999 } : {}}
  bodyStyle={{
    backgroundColor: '#f9f9f9',
    padding: apiRefDrawerFullscreen ? '16px 24px' : '12px'
  }}
  destroyOnClose
  maskClosable
  title={
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      {/* Left Col: Title */}
      <Col>
        <Typography.Title level={4} style={{ margin: 0 }}>
          API Reference
        </Typography.Title>
      </Col>

      {/* Right Col: Dropdown + Expand/Collapse Button */}
      <Col>
        <Space>
        <Select
  showSearch
  style={{ width: 220 }}
  placeholder="Switch API Docs"
  value={selectedApi?.name}
  onChange={handleDocSwitch}
  filterOption={(inputValue, option) => {
    if (!option) return false;
    const label = option.children?.toLowerCase() || "";
    return label.includes(inputValue.toLowerCase());
  }}
>
  {apiGroups.flatMap((g) =>
    g.apis
      .filter((api) => api.longDescription && api.longDescription.trim() !== "")
      .map((api) => (
        <Select.Option key={`${g.group}###${api.name}`} value={api.name}>
          {g.group} / {api.name}
        </Select.Option>
      ))
  )}
</Select>


          <Button
            type="text"
            icon={apiRefDrawerFullscreen ? <CompressOutlined /> : <ExpandOutlined />}
            onClick={() => setApiRefDrawerFullscreen((prev) => !prev)}
          />
        </Space>
      </Col>
    </Row>
  }
>
  
{selectedApi && (
    <Card
      bordered={false}
      style={{ marginBottom: '20px' }}
      bodyStyle={{ padding: '10px' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {selectedApi.name}
          </Typography.Title>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography.Text type="secondary" style={{ fontSize: '14px' }}>
            {SPECTER_ENV_URL + selectedApi.route}
          </Typography.Text>

      <RouteCopyButton route= {SPECTER_ENV_URL + selectedApi.route}/>
        </div>
        </div>
        <div>
          <Tag
            style={{ fontSize: '14px', padding: '4px 8px' }}
            color={
              selectedApi.method === 'GET'
                ? 'green'
                : selectedApi.method === 'POST'
                ? 'blue'
                : selectedApi.method === 'PUT'
                ? 'orange'
                : 'red'
            }
          >
            {selectedApi.method}
          </Tag>
        </div>
      </div>
    </Card>
  )}
  {selectedApi?.examples && selectedApi.examples.length > 0 && (
  <div 
    style={{
      display: 'flex', 
      justifyContent: 'flex-end', 
      marginBottom: '16px'
    }}
  >
<Button
      type="dashed"
      shape="round"
      icon={<ArrowRightOutlined />}
      onClick={() => {
        setDocsDrawerVisible(false);
        setExamplesDrawerVisible(true);
      }}
    >
      View Usage Examples
    </Button>
  </div>
)}


  {selectedApi?.longDescription ? (
    <div
    className="documentation-content"
      style={{ overflowY: "auto", maxHeight: "calc(100vh - 130px)" }}
      dangerouslySetInnerHTML={{ __html: selectedApi.longDescription }}
    />
  ) : (
    <Typography.Text>No documentation available.</Typography.Text>
  )}
</Drawer>


<Drawer
  placement="right"
  visible={examplesDrawerVisible}
  onClose={() => {
    setExamplesDrawerVisible(false);
    setExamplesDrawerFullscreen(false); // Reset on close
  }}
  // If fullscreen, force the drawer to be 100% wide
  width={examplesDrawerFullscreen ? '100%' : 800}
  // If fullscreen, optionally set top / zIndex / margin etc.
  style={
    examplesDrawerFullscreen
      ? {
          top: 0,
          margin: 0,
          padding: 0,
          zIndex: 9999, // ensure on top
        }
      : {}
  }
  // Adjust the drawer body padding if you want a slightly different style in fullscreen
  bodyStyle={{
    backgroundColor: '#f9f9f9',
    padding: examplesDrawerFullscreen ? '16px 24px' : '12px',
  }}
  destroyOnClose
  maskClosable
  // Option A: A fully custom header using `title` prop
  title={
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Col>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Usage Examples
        </Typography.Title>
      </Col>
      <Col>
        <Button
          type="text"
          icon={examplesDrawerFullscreen ? <CompressOutlined /> : <ExpandOutlined />}
          onClick={() => setExamplesDrawerFullscreen((prev) => !prev)}
        />
      </Col>
    </Row>
  }
>
  <ExampleDrawerContent 
  examples={selectedApi?.examples}
  selectedApi={selectedApi} 
  onClose={() => setExamplesDrawerVisible(false)}
  setDocsDrawerVisible={setDocsDrawerVisible}
  setExamplesDrawerVisible={setExamplesDrawerVisible}

   />
</Drawer>
    
          {selectedApi && (
            <ApiForm
              selectedApi={selectedApi}
              initialRequestParams={requestParams}
              onRunRequest={handleRunRequest}
              onPreviewRequest={handlePreviewRequest}
            />
          )}
        </div>

        <div style={{ flex: 1 }}>
          <Card
            className="code-snippet-section"
            title="Request"
            bordered={false}
            style={{ marginBottom: '20px' }}
            extra={
              <Button type="text" onClick={toggleRequestSize}>
                {isRequestExpanded ? <CompressOutlined /> : <ExpandOutlined />}{' '}
                {isRequestExpanded ? 'Collapse' : 'Expand'}
              </Button>
            }
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Select defaultValue="Node" style={{ width: 120 }} onChange={handleLanguageChange}>
                <Option value="Node">Node</Option>
                <Option value="Python">Python</Option>
                <Option value="cURL">cURL</Option>
                <Option value="C#">C#</Option>
                <Option value="Java">Java</Option>
                <Option value="Go">Go</Option>
                <Option value="PHP">PHP</Option>
                <Option value="Ruby">Ruby</Option>
                <Option value="Swift">Swift</Option>
              </Select>

              <Button
                type="link"
                icon={<CopyOutlined />}
                onClick={() => {
                  if (codeSnippet) {
                    navigator.clipboard.writeText(codeSnippet);
                    message.success('Code snippet copied to clipboard');
                  }
                }}
                style={{ float: 'right' }}
              >
                Copy
              </Button>
            </div>
            <RequestCodeDisplay
              language={selectedLanguage}
              codeSnippet={codeSnippet}
              expanded={isRequestExpanded}
            />
          </Card>

          <Card
            title="Response"
            className="response-section"
            bordered={false}
            extra={
              <Button type="text" onClick={toggleEditorSize}>
                {editorExpanded ? <CompressOutlined /> : <ExpandOutlined />}{' '}
                {editorExpanded ? 'Collapse' : 'Expand'}
              </Button>
            }
          >
            <ApiResponse response={response} expanded={editorExpanded} />
          </Card>
        </div>
      </div>
    </>
  )}
</Content>
    {/* Help/Documentation Drawer */}
    <Drawer
  placement="right"
  onClose={() => {
    // When the drawer closes, optionally reset it from fullscreen
    setHelpVisible(false);
    setHelpDrawerFullscreen(false);
  }}
  visible={helpVisible}
  width={helpDrawerFullscreen ? '100%' : (window.innerWidth < 768 ? '100%' : 900)}
  // In fullscreen mode, you might also want to force it to fill the screen:
  style={
    helpDrawerFullscreen
      ? {
          top: 0,
          // height: '100vh',  // Typically not needed if you rely on the default drawer's mask
          // If you want absolutely no margins at all, you can do:
          margin: 0, padding: 0, 
          zIndex: 9999
        }
      : {}
  }
  bodyStyle={{
    // Remove or adjust padding when in fullscreen if you like
    backgroundColor: '#f9f9f9',
    padding: helpDrawerFullscreen ? '12px 24px' : '12px',
  }}
  destroyOnClose
  maskClosable
  headerStyle={{ backgroundColor: '#f0f2f5', borderBottom: '1px solid #e8e8e8' }}
  // We replace the 'title' prop with a custom header
  title={
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Col>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Specter API Documentation
        </Typography.Title>
      </Col>
      <Col>
        <Button
          type="text"
          icon={helpDrawerFullscreen ? <CompressOutlined /> : <ExpandOutlined />}
          onClick={() => setHelpDrawerFullscreen(prev => !prev)}
        />
      </Col>
    </Row>
  }
>
        {/* Search Field */}
        <Input.Search
          placeholder="Search documentation..."
          onChange={(e) => setDocSearchQuery(e.target.value)}
          value={docSearchQuery}
          style={{ marginBottom: '16px' }}
          allowClear
        />
        <div
          style={{
            display: 'flex',
            flexDirection: window.innerWidth < 768 ? 'column' : 'row',
            gap: '16px'
          }}
        >
          {/* Left Navigation: List of documentation titles */}
          <div
            style={{
              flex: '1 1 24%',
              maxHeight: '80vh',
              overflowY: 'auto',
              
              borderRight: window.innerWidth < 768 ? 'none' : '1px solid #e8e8e8',
              paddingRight: window.innerWidth < 768 ? '0' : '4px'
            }}
          >
<List
  itemLayout="horizontal"
  dataSource={filteredDocs}
  renderItem={(doc) => (
    <List.Item
      style={{
        padding: '8px 16px',
        cursor: 'pointer',
        borderBottom: '1px solid #e8e8e8',
        minHeight: '60px',
        backgroundColor: selectedDoc && selectedDoc._id === doc._id ? "#f0f2f5" : "inherit"
      }}
      onClick={() => openDocumentation(doc)}
    >
<List.Item.Meta
  title={<span style={{ fontWeight: 'normal' }}>{doc.title}</span>}
/>

    </List.Item>
  )}
/>

          </div>
          {/* Right Pane: Documentation content */}
          <div
            style={{
              flex: '1 1 60%',
              maxHeight: '80vh',
              overflowY: 'auto',
              paddingRight: '20px'
            }}
          >
            {helpContent.content ? (
              <div
                className="documentation-content"
                dangerouslySetInnerHTML={{ __html: helpContent.content }}
              />
            ) : (
              <Typography.Text>No documentation selected.</Typography.Text>
            )}
          </div>
        </div>
       
      </Drawer>

<HistoryModal
  visible={historyModalVisible}
  onClose={() => setHistoryModalVisible(false)}
  memberName={memberName}
  API_BASE_URL={API_BASE_URL}
  selectedEnv={selectedEnv} // Add this line
  onLoadRequestFromHistory={handleLoadRequestFromHistory} 
/>

<ApiKeySelectorModal
  visible={showApiKeySelector}
  onSelect={(key) => {
    setApiKey(key);
    setShowApiKeySelector(false);
    message.success('API Key selected');
  }}
  onCancel={() => setShowApiKeySelector(false)}
  orgId={selectedOrg ? selectedOrg.id : ''}
  authToken={authToken}
  orgApiKeys={selectedOrg ? orgApiKeys[selectedOrg.id] : {}}
  globalEnv={selectedEnv} 
  devIp={devIp}  
/>
<AskAIModal
        visible={askAIModalVisible}
        onClose={() => setAskAIModalVisible(false)}
      />
{isLoggedIn && (
  <Button
    type="primary"
    shape="circle"
    icon={
      <img 
        src="/images/wand.png" 
        alt="Wand icon" 
        style={{ width: '24px', height: '24px', objectFit: 'contain' }} 
      />
    }
    size="large"
    style={{
      position: 'fixed',
      bottom: askaiButtonBottom,
      right: 24,
      zIndex: 1000,
      backgroundColor: '#3B82F6',
      border: 'none',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease'
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = 'scale(1.1)';
      e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.25)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = 'scale(1)';
      e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.15)';
    }}
    onClick={() => setAskAIModalVisible(true)}
  />
)}





      <Footer ref={footerRef} style={{ backgroundColor: '#272B30', color: '#ffffff', padding: '40px 50px' }}>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          {/* Left Side: Logo and Description */}
          <Col xs={24} sm={12} md={8} lg={8}>
            <div style={{ marginBottom: '20px' }}>
              {/* Replace with your logo */}
              <img src="/images/specter_logo_2.png" alt="Specter Logo" style={{ width: '150px' }} />
            </div>
            <Text style={{ color: '#ffffff' }}>Specter ©2024 | Created by Dirtcube Interactive</Text>
          </Col>

          {/* Middle: Navigation Links */}
          <Col xs={24} sm={12} md={8} lg={8}>
            <Space direction="vertical" size="small">
              <Link href="https://www.specterapp.xyz/about"  target="_blank"style={{ color: '#ffffff' }}>
                About Us
              </Link>
              <Link href="mailto:hello@specterapp.xyz" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>
                Contact
              </Link>
              <Link href="https://www.specterapp.xyz/privacyPolicy" target="_blank"style={{ color: '#ffffff' }}>
                
                Privacy Policy
              </Link>
              <Link href="https://www.specterapp.xyz/termsOfService" target="_blank" style={{ color: '#ffffff' }}>
                Terms of Service
              </Link>
            </Space>
          </Col>

          {/* Right Side: Social Media Icons */}
          <Col xs={24} sm={24} md={8} lg={8} style={{ textAlign: 'right' }}>
            <Space size="large">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
  <img 
    src={`${process.env.PUBLIC_URL}/images/x.svg`} 
    alt="Twitter" 
    style={{ width: '24px', height: '22px', filter: 'invert(1)' }} 
  />
</a>


              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
            </Space>
          </Col>
        </Row>
      </Footer>
    </Layout>
  </ConfigProvider>
  </>
);
}

export default App;



