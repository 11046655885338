import React from 'react';
import { Collapse,Card,Tag,Button,
   Tabs, Typography, Divider, Badge } from 'antd';
   
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
// Import your RouteCopyButton if you're using it
import RouteCopyButton from './RouteCopyButton';
import {ArrowRightOutlined} from '@ant-design/icons';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Paragraph } = Typography;
const SPECTER_ENV_URL = process.env.REACT_APP_SPECTER_ENV_URL;


const ExampleDrawerContent = ({ examples ,selectedApi,setExamplesDrawerVisible,setDocsDrawerVisible}) => {
  if (!examples || examples.length === 0) {
    return <Typography.Text>No examples available.</Typography.Text>;
  }
  
  const getBadgeColor = (status) => {
    if (status >= 200 && status < 300) return '#52c41a'; // Green for success
    if (status >= 300 && status < 400) return '#faad14'; // Gold for redirection
    if (status >= 400 && status < 500) return '#f5222d'; // Red for client errors
    if (status >= 500) return '#a8071a';              // Dark red for server errors
    return '#2A84FF'; // Fallback color
  };

  return (
    <div className="example-drawer-content">
          {/* --- 1) Card at the top, same style as your Reference Drawer --- */}
          {selectedApi && (
        <Card
          bordered={false}
          style={{ marginBottom: '20px' }}
          bodyStyle={{ padding: '10px' }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            {/* Left side: API Name + route */}
            <div>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {selectedApi.name}
              </Typography.Title>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography.Text type="secondary" style={{ fontSize: '14px' }}>
                {SPECTER_ENV_URL + selectedApi.route}
          </Typography.Text>

      <RouteCopyButton route= {SPECTER_ENV_URL + selectedApi.route}/>
              </div>
            </div>

            {/* Right side: Tag for HTTP method */}
            <div>
              <Tag
                style={{ fontSize: '14px', padding: '4px 8px' }}
                color={
                  selectedApi.method === 'GET'
                    ? 'green'
                    : selectedApi.method === 'POST'
                    ? 'blue'
                    : selectedApi.method === 'PUT'
                    ? 'orange'
                    : 'red'
                }
              >
                {selectedApi.method}
              </Tag>
            </div>
          </div>
        </Card>
        
      )}
        {/* 1) If the selected API has doc content, show “View API Reference” button */}
  {selectedApi?.longDescription && (
    <div 
    style={{
      display: 'flex', 
      justifyContent: 'flex-end', 
      marginBottom: '16px'
    }}
  >
<Button
      type="dashed"
      shape="round"
      icon={<ArrowRightOutlined />}
      onClick={() => {
        setExamplesDrawerVisible(false); // close examples
        setDocsDrawerVisible(true);      // open docs
      }}
    >
      View API Reference
    </Button>
    </div>
  )}
      <Collapse accordion>
        {examples.map((ex, index) => {
          // Create a header that includes the scenario name and response status (if available)
          const headerContent = (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{ex.scenario}</span>
              {ex.response && ex.response.status && (
                <Badge 
                  count={ex.response.status} 
                  style={{ backgroundColor: getBadgeColor(ex.response.status), fontSize: '0.85em' }}
                  showZero
                  overflowCount={1000} 
                />
              )}
            </div>
          );
          
          return (
            <Panel header={headerContent} key={index}>
              <Paragraph strong>Description:</Paragraph>
              {/* Render the description as HTML for proper formatting */}
              <div
                className="documentation-content"
                style={{
                  padding: '10px',
                  backgroundColor: '#f7f7f7',
                  borderRadius: '6px',
                  fontSize: '14px',
                  lineHeight: '1.6',
                  color: '#333',
                  marginBottom: '16px',
                }}
                dangerouslySetInnerHTML={{ __html: ex.description }}
              />
              <Divider />
              <Tabs defaultActiveKey="request" type="card">
                <TabPane tab="Request" key="request">
                  <Tabs defaultActiveKey="headers" type="line">
                    <TabPane tab="Headers" key="headers">
                      <SyntaxHighlighter language="json" style={okaidia}>
                        {ex.request.headers}
                      </SyntaxHighlighter>
                    </TabPane>
                    <TabPane tab="Body" key="body">
                      <SyntaxHighlighter language="json" style={okaidia}>
                        {ex.request.body}
                      </SyntaxHighlighter>
                    </TabPane>
                  </Tabs>
                </TabPane>
                <TabPane tab="Response" key="response">
                  <Tabs defaultActiveKey="headers" type="line">
                    <TabPane tab="Headers" key="headers">
                      <SyntaxHighlighter language="json" style={okaidia}>
                        {ex.response.headers}
                      </SyntaxHighlighter>
                    </TabPane>
                    <TabPane tab="Body" key="body">
                      <SyntaxHighlighter language="json" style={okaidia}>
                        {ex.response.body}
                      </SyntaxHighlighter>
                    </TabPane>
                  </Tabs>
                </TabPane>
              </Tabs>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default ExampleDrawerContent;
