// src/components/ApiGroupsModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Input, Space, Popconfirm, message, Form, Collapse } from 'antd';
import { PlusOutlined, EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Panel } = Collapse;

function ApiGroupsModal({ visible, onClose }) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [form] = Form.useForm();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/groups`);
      setGroups(response.data);
    } catch (error) {
      message.error('Failed to fetch API groups');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchGroups();
      // Reset form and editing state when modal opens
      form.resetFields();
      setIsEditing(false);
      setEditingGroup(null);
      setFormVisible(false);
    }
  }, [visible, form]);

  const handleDelete = async (groupId) => {
    try {
      await axios.delete(`${API_BASE_URL}/groups/${groupId}`);
      setGroups((prev) => prev.filter((group) => group._id !== groupId));
      message.success('Group deleted successfully');
    } catch (error) {
      message.error('Failed to delete group');
    }
  };

  const handleEdit = (group) => {
    setIsEditing(true);
    setEditingGroup(group);
    form.setFieldsValue({
      name: group.name,
      sortOrder: group.sortOrder,
      description: group.description,
    });
    setFormVisible(true);
  };

  const handleSubmit = async (values) => {
    try {
      if (isEditing && editingGroup) {
        // Update existing group
        const response = await axios.put(`${API_BASE_URL}/groups/${editingGroup._id}`, values);
        message.success('Group updated successfully');
        setGroups((prev) =>
          prev.map((group) => (group._id === editingGroup._id ? response.data.data : group))
        );
      } else {
        // Create new group
        const response = await axios.post(`${API_BASE_URL}/groups`, values);
        message.success('Group created successfully');
        setGroups((prev) => [...prev, response.data.data]);
      }
      form.resetFields();
      setIsEditing(false);
      setEditingGroup(null);
      setFormVisible(false);
    } catch (error) {
      message.error('Failed to save group');
    }
  };

  const columns = [
    {
      title: 'No',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sort Order',
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      sorter: (a, b) => a.sortOrder - b.sortOrder,
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'description',
    //   key: 'description',
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this group?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title="Manage API Groups"
      visible={visible}
      onCancel={() => {
        form.resetFields();
        setIsEditing(false);
        setEditingGroup(null);
        onClose();
      }}
      footer={null}
      width={800}
    >
      <Space style={{ marginBottom: 16 }}>
        <Button icon={<ReloadOutlined />} onClick={fetchGroups}>
          Refresh
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsEditing(false);
            setEditingGroup(null);
            form.resetFields();
            setFormVisible(true);
          }}
        >
          New Group
        </Button>
      </Space>
      <Table columns={columns} dataSource={groups} rowKey="_id" loading={loading} pagination={false} />
      {formVisible && (
        <Collapse defaultActiveKey={['1']} style={{ marginTop: 24 }}>
          <Panel header={isEditing ? 'Update Group' : 'Create Group'} key="1">
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Group Name"
                rules={[{ required: true, message: 'Please enter group name' }]}
              >
                <Input placeholder="Group Name" />
              </Form.Item>
              <Form.Item name="sortOrder" label="Sort Order">
                <Input type="number" placeholder="Sort Order" />
              </Form.Item>
              <Form.Item name="description" label="Description">
                <Input.TextArea placeholder="Description" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {isEditing ? 'Update Group' : 'Create Group'}
              </Button>
            </Form>
          </Panel>
        </Collapse>
      )}
    </Modal>
  );
}

export default ApiGroupsModal;
