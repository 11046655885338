import { useState} from "react";
import { Button } from 'antd';
import {CheckOutlined,CopyOutlined } from '@ant-design/icons';

function RouteCopyButton({ route }) {
    const [copied, setCopied] = useState(false);
  
    const handleCopyRoute = () => {
      navigator.clipboard.writeText(route).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      });
    };
  
    return (
      <Button
        type="text"
        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
        onClick={handleCopyRoute}
        style={{ padding: '0 4px' }}
      >
        {copied ? 'Copied!' : 'Copy'}
      </Button>
    );
  }
  
  export default RouteCopyButton