// src/components/DocumentationModal.js
import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Button,
  Form,
  Select,
  Divider,
  Space,
  message,
  Tooltip,
  Input,
  AutoComplete
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";

const { Option } = Select;

const DocumentationModal = ({ visible, onClose, onDocumentationUpdate }) => {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingDoc, setEditingDoc] = useState(null);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch all documentation items from the backend
  const fetchDocs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/documentation`);
      setDocs(response.data);
    } catch (error) {
      message.error("Failed to fetch documentation");
    }
    setLoading(false);
  };

  // Fetch distinct categories from the backend
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/documentation/categories/distinct`);
      setCategories(response.data);
    } catch (error) {
      message.error("Failed to fetch categories");
    }
  };

  // When the modal becomes visible, load documentation and categories.
  useEffect(() => {
    if (visible) {
      fetchDocs();
      fetchCategories();
      form.resetFields();
      setEditingDoc(null);
      setEditorContent("");
    }
  }, [visible]);

  // Handle delete documentation
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/documentation/${id}`);
      setDocs(prev => prev.filter(doc => doc._id !== id));
      message.success("Documentation deleted");
      if (onDocumentationUpdate) onDocumentationUpdate();
    } catch (error) {
      message.error("Failed to delete documentation");
    }
  };

  const handleRefresh = () => {
    fetchDocs();
    fetchCategories();
    message.success("Documentation refreshed");
  };
  

  // Handle form submission for create/update
  const handleFormSubmit = async (values) => {
    // Include the editor content in the values
    values.content = editorContent;
    try {
      if (editingDoc) {
        await axios.put(`${API_BASE_URL}/documentation/${editingDoc._id}`, values);
        message.success("Documentation updated");
      } else {
        await axios.post(`${API_BASE_URL}/documentation`, values);
        message.success("Documentation created");
      }
      fetchDocs();
      form.resetFields();
      setEditingDoc(null);
      setEditorContent("");
      if (onDocumentationUpdate) onDocumentationUpdate();
    } catch (error) {
      message.error("Failed to save documentation");
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Tooltip title={record.content} placement="right">
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: tags => (tags ? tags.join(", ") : ""),
    },
    {
      title: "Sort Order",
      dataIndex: "sortOrder",
      key: "sortOrder",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingDoc(record);
              form.setFieldsValue(record);
              setEditorContent(record.content); // preload editor with existing content
            }}
          >
            Edit
          </Button>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title="Manage Documentation"
      visible={visible}
      onCancel={() => {
        form.resetFields();
        setEditingDoc(null);
        setEditorContent("");
        onClose();
      }}
      footer={null}
      width={800}
    >
 <Space style={{ marginBottom: 16 }}>
  <Button icon={<ReloadOutlined />} onClick={handleRefresh}>
    Refresh
  </Button>
</Space>

      <Table
        columns={columns}
        dataSource={docs}
        rowKey="_id"
        loading={loading}
        pagination={false}
      />
      <Divider />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        style={{ marginTop: 24 }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title" }]}
        >
          <Input placeholder="Documentation title" />
        </Form.Item>
        <Form.Item
  name="category"
  label="Category"
  rules={[{ required: true, message: "Please select or enter a category" }]}
>
  <AutoComplete
    placeholder="Type or select a category"
    options={categories.map((cat) => ({ value: cat }))}
    filterOption={(inputValue, option) =>
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    }
    allowClear
  />
</Form.Item>

        <Form.Item name="tags" label="Tags">
          <Select mode="tags" placeholder="Add tags" />
        </Form.Item>
        <Form.Item
          name="sortOrder"
          label="Sort Order"
          rules={[{ required: true, message: "Please enter the sort order" }]}
        >
          <Input type="number" placeholder="e.g., 1" />
        </Form.Item>
        <Form.Item
          label="Documentation Content"
          rules={[{ required: true, message: "Please enter the documentation content" }]}
        >
          <Editor
            apiKey="0k7t1yanut3hbo2kq78m4rik6iy2tc50gut6gsh3q2g7wed2"
            init={{
              height: 300,
              menubar: true,
              plugins: ["table", "lists", "link", "image", "code", "codesample"],
              toolbar:
                "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table codesample | code",
              codesample_global_prismjs: true,
              codesample_languages: [
                { text: 'HTML/XML', value: 'markup' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'CSS', value: 'css' },
                { text: 'PHP', value: 'php' },
                { text: 'Ruby', value: 'ruby' },
                { text: 'Python', value: 'python' },
                { text: 'Java', value: 'java' },
                { text: 'C', value: 'c' },
                { text: 'C#', value: 'csharp' },
                { text: 'C++', value: 'cpp' },
                { text: 'JSON', value: 'json' }
              ]
            }}
        
            onEditorChange={(content) => setEditorContent(content)}
            value={editorContent}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {editingDoc ? "Update Documentation" : "Add Documentation"}
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setEditingDoc(null);
                setEditorContent("");
              }}
            >
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DocumentationModal;
