// src/components/ProtectedImage.js
import React, { useState, useEffect } from 'react';

const ProtectedImage = ({ src, apiKey, alt, style }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let objectUrl = null;
    const fetchImage = async () => {
      try {
        const res = await fetch(src, {
          method: 'GET',
          headers: { 'Api-Key': apiKey }
        });
        if (!res.ok) throw new Error('Failed to fetch image');
        const blob = await res.blob();
        objectUrl = URL.createObjectURL(blob);
        setImageUrl(objectUrl);
      } catch (err) {
        console.error(err);
      }
    };

    if (src && apiKey) {
      fetchImage();
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [src, apiKey]);

  if (!imageUrl) return null;
  return <img src={imageUrl} alt={alt} style={style} />;
};

export default ProtectedImage;
